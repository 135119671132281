import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const StyledSlideInDrawerContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-content: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? "all" : "none")};
  transition: opacity 0.3s ease-in-out;
  z-index: 10000;
`;

export const StyledSlideInDrawerOverlay = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.gray90};
  opacity: ${({ isOpen }) => (isOpen ? 0.32 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100%;
`;

export const StyledSlideInDrawerBody = styled(StyledFlex)<{
  isOpen: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  padding: 48px 32px;
  height: 100vh;
  overflow-y: auto;
  max-width: 1011px;
  width: 70%;
  transition: all 0.3s ease-out;
  transform: ${({ isOpen }) => `translateX(${isOpen ? "0" : "100%"})`};
`;

export const StyledSlideInDrawerButton = styled.button`
  width: 36px;
  height: 36px;
`;
