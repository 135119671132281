import { IUser } from "@/types/user";
import API, { BaseResponse } from "./API";
import Endpoints from "./endpoints";

export interface ITokens {
  refreshToken: string;
  accessToken: string;
}

export interface ILoginRequest {
  email: string;
  password: string;
  redirectUrl?: string;
}

export interface ILoginResponse {
  user: IUser;
  tokens: ITokens;
}

export interface IApproveRequest {
  email: string;
  token: string;
}

export interface IApproveResponse {
  user: IUser;
  tokens: ITokens;
}

export interface ICreateAdminRequest {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  redirectUrl: string;
}

export interface ICreateAdminResponse {
  user: {};
  requestId: string;
}

export interface IVerifyAdminRequest {
  email: string;
  token: string;
  currentPassword: string;
  newPassword: string;
}

export interface IVerifyAdminResponse {
  user: IUser;
  tokens: ITokens;
}

export interface IResetPasswordRequest {
  email: string;
}

export interface IConfirmResetPasswordRequest {
  email: string;
  token: string;
  password: string;
}

export const login = async (
  payload: ILoginRequest
): Promise<BaseResponse<ILoginResponse>> => {
  return API.post<ILoginRequest, ILoginResponse>(Endpoints.LOGIN, payload);
};

export const forgotPassword = async (
  payload: IResetPasswordRequest
): Promise<BaseResponse<ILoginResponse>> => {
  return API.post<IResetPasswordRequest, ILoginResponse>(
    Endpoints.FORGOT_PASSWORD,
    payload
  );
};

export const resetPassword = async (
  payload: IConfirmResetPasswordRequest
): Promise<BaseResponse<ILoginResponse>> => {
  return API.patch<IConfirmResetPasswordRequest, ILoginResponse>(
    Endpoints.RESET_PASSWORD,
    payload
  );
};

export const createAdmin = async (
  payload: ICreateAdminRequest
): Promise<BaseResponse<ICreateAdminResponse>> => {
  return API.post<ICreateAdminRequest, ICreateAdminResponse>(
    Endpoints.CREATE_ADMIN,
    payload
  );
};

export const verifyAdmin = async (
  payload: IVerifyAdminRequest
): Promise<BaseResponse<IVerifyAdminResponse>> => {
  return API.post<IVerifyAdminRequest, IVerifyAdminResponse>(
    Endpoints.VERIFY_ADMIN,
    payload
  );
};

export const approveAdmin = async (
  payload: IApproveRequest
): Promise<BaseResponse<IApproveResponse>> => {
  return API.post<IApproveRequest, IApproveResponse>(
    Endpoints.APPROVE_ADMIN,
    payload
  );
};
