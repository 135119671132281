import React from "react";
import {
  StyledSlideInDrawerBody,
  StyledSlideInDrawerButton,
  StyledSlideInDrawerContainer,
  StyledSlideInDrawerOverlay,
} from "./SlideInDrawer.styles";
import { ISlideInDrawerProps } from "./SlideInDrawer.types";
import { Close } from "@/assets/vectors";

const SlideInDrawer: React.FC<ISlideInDrawerProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  return (
    <StyledSlideInDrawerContainer isOpen={isOpen}>
      <StyledSlideInDrawerOverlay isOpen={isOpen} onClick={onClose} />
      <StyledSlideInDrawerBody vertical gap={38} isOpen={isOpen}>
        <StyledSlideInDrawerButton onClick={onClose}>
          <Close />
        </StyledSlideInDrawerButton>
        {children}
      </StyledSlideInDrawerBody>
    </StyledSlideInDrawerContainer>
  );
};

export default SlideInDrawer;
