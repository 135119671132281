import { Input } from "antd";
import styled from "styled-components";

export const StyledInput = styled(Input)`
  height: 48px;
  width: 100%;
  padding: 14px 16px;
  & .ant-input-prefix {
    margin-inline-end: 8px;
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  width: 100%;
  padding: 15px 16px;
  &.ant-input {
    min-height: 149px;
    border-radius: 16px;
    border: ${({ theme }) => `1px solid ${theme.color.black70}`};
  }
`;
