import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  ManageConversionRateLayout,
  StyledCurrentRate,
  StyledUpdateConversionRateActionBtnContainer,
} from "./ManageConversionRate.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { useState } from "react";
import { ConfigProvider, Form } from "antd";
import { UpdateConversionRateFieldType } from "./ManageConversionRate.types";
import { Download, Naira, Plus } from "@/assets/vectors";
import { StyledCard } from "@/components/Dashboard/Card/Card.styles";
import {
  StyledButton,
  StyledExportButton,
} from "@/components/Dashboard/Button/Button.styles";
import type { ColumnsType } from "antd/es/table";
import Table from "@/components/Dashboard/Table/Table";
import Modal from "@/components/Dashboard/Modal/Modal";
import { StyledForm } from "@/components/Auth/Form/Form.styles";
import { required } from "@/utils/validation";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import { globalTheme } from "@/theme/theme";

interface DataType {
  key: string;
  rate: string;
  lastUpdated: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Current Rate ",
    key: "rate",
    render: (_, { rate }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {rate}
      </StyledTypography>
    ),
    width: "79%",
  },
  {
    title: "Last Updated ",
    key: "lastUpdated",
    render: (_, { lastUpdated }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {lastUpdated}
      </StyledTypography>
    ),
    width: "21%",
  },
];

const ManageConversionRate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm<UpdateConversionRateFieldType>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values: UpdateConversionRateFieldType) => {
    console.log("Success:", values);
  };

  return (
    <ManageConversionRateLayout gap={32} vertical $alignSelf="stretch">
      {/* modal starts */}
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        heading="Update Conversion Rate"
        footer={
          <StyledUpdateConversionRateActionBtnContainer justify="flex-end">
            <StyledButton onClick={hideModal} type="default">
              Cancel
            </StyledButton>
            <StyledButton
              form="update-conversion-rate"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              Submit
            </StyledButton>
          </StyledUpdateConversionRateActionBtnContainer>
        }
      >
        <StyledForm
          form={form}
          layout="vertical"
          name="update-conversion-rate"
          onFinish={onFinish}
        >
          <StyledFlex gap={32} vertical $alignSelf="stretch">
            <StyledCurrentRate>
              <StyledTypography
                as="p"
                $variantColor="black100"
                $variantSize="md"
              >
                Current Rate: 1 shekel = ₦200
              </StyledTypography>
            </StyledCurrentRate>
            <StyledForm.Item<UpdateConversionRateFieldType>
              name="rate"
              rules={[required("Please input your reason!")]}
              label="New Rate"
            >
              <StyledInput prefix={<Naira />} placeholder="Enter New Rate" />
            </StyledForm.Item>
          </StyledFlex>
        </StyledForm>
      </Modal>
      {/* modal ends */}
      <StyledFlex gap={20} align="center" justify="space-between">
        <StyledFlex gap={4} vertical $alignSelf="stretch">
          <StyledTypography
            $variantColor="gray90"
            $variantSize="2xl"
            $fontWeight={500}
          >
            Shekel Conversion Rate Management
          </StyledTypography>
          <StyledTypography $variantColor="black80" $variantSize="sm">
            Managing Shekel-to-Naira Conversion Rate
          </StyledTypography>
        </StyledFlex>
        <StyledButton onClick={() => showModal()} type="primary">
          <Plus />
          <span>Update Shekel Conversion Rate</span>
        </StyledButton>
      </StyledFlex>
      <StyledCard>
        <StyledFlex gap={8} $alignSelf="stretch" vertical>
          <StyledTypography
            $variantColor="black90"
            $variantSize="sm"
            $fontWeight={400}
          >
            Shekel-to-Currency Conversion Rate
          </StyledTypography>
          <StyledTypography
            $variantColor="black100"
            $variantSize="2xl"
            $fontWeight={500}
          >
            1 shekel = ₦200
          </StyledTypography>
          <StyledTypography
            $variantColor="black90"
            $variantSize="xs"
            $fontWeight={400}
          >
            Updated January 2024
          </StyledTypography>
        </StyledFlex>
      </StyledCard>
      <StyledFlex vertical gap={16}>
        <StyledFlex gap={20} align="center" justify="space-between">
          <StyledTypography
            $variantColor="gray90"
            $variantSize="lg"
            $fontWeight={500}
          >
            Shekel Conversion Rate History
          </StyledTypography>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: globalTheme.color.white,
                  primaryColor: globalTheme.color.primary,
                },
              },
            }}
          >
            <StyledExportButton type="primary">
              <Download />
              <span>Export</span>
            </StyledExportButton>
          </ConfigProvider>
        </StyledFlex>
        <Table
          columns={columns}
          dataSource={[
            {
              key: 0,
              rate: "1 shekel = ₦200",
              lastUpdated: "January 30, 2024 12:00 PM",
            },
            {
              key: 1,
              rate: "1 shekel = ₦150",
              lastUpdated: "January 30, 2024 12:00 PM",
            },
            {
              key: 2,
              rate: "1 shekel = ₦100",
              lastUpdated: "January 30, 2024 12:00 PM",
            },
          ]}
          isPaginated={false}
        />
      </StyledFlex>
    </ManageConversionRateLayout>
  );
};

export default ManageConversionRate;
