import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const ManageConversionRateLayout = styled(StyledFlex)`
  width: 100%;
`;

export const StyledUpdateConversionRateActionBtnContainer = styled(StyledFlex)`
  & > * {
    width: 50%;
  }
`;

export const StyledCurrentRate = styled.div`
  padding: 10px 14px;
  background-color: ${({ theme }) => `${theme.color.yellow}`};
  text-align: center;
  border-radius: 32px;
`;
