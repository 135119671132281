import {
  IPaginationMeta,
  IPaginationParams,
  ISODateString,
} from "@/types/global";
import API, { BaseResponse } from "./API";
import { IUser } from "@/types/user";
import Endpoints from "./endpoints";

// type SingleUser = Omit<IUser, "profile"> & {
//   profile: Omit<IUser["profile"], "avartar"> & {
//     avatar: { publicURL: string } | null;
//   };
// };

type SingleUser = IUser;

interface IWatcherWallet {
  adBudget: number;
  adSpend: number;
  balance: string;
  createdAt: ISODateString;
  id: number;
  updatedAt: ISODateString;
  userId: number;
  virtualAccount: null;
}

interface ISingleWatcherStats {
  viewedAds: number;
  likedComments: number;
  shekelsEarned: number;
  tribes: number;
}

interface IUserParams {
  email?: string;
  search?: string;
}

interface IEditUser {
  username?: string;
  phoneNumber?: string;
  firstName?: string;
  bio?: string;
  lastName?: string;
  gender?: string;
  address?: string;
  dateOfBirth?: string;
  status?: "ACTIVE" | "BANNED" | "SUSPENDED";
}

interface ISuspendUser {
  reason: string;
  reactivateAfter: string;
}

export const getAdvertisers = async (
  params: IPaginationParams & IUserParams
): Promise<BaseResponse<IUser[], IPaginationMeta>> => {
  return API.get<IUser[], IPaginationMeta>(Endpoints.USERS_SEARCH, {
    params: { ...params, role: "advertiser" },
  });
};

export const getRecentUsers = async (
  params: IPaginationParams & IUserParams
): Promise<BaseResponse<IUser[], IPaginationMeta>> => {
  return API.get<IUser[], IPaginationMeta>(Endpoints.USERS_SEARCH, {
    params: { ...params },
  });
};

export const getWatchers = async (
  params: IPaginationParams & IUserParams
): Promise<BaseResponse<IUser[], IPaginationMeta>> => {
  return API.get<IUser[], IPaginationMeta>(Endpoints.USERS_SEARCH, {
    params: { ...params, role: "user" },
  });
};

export const getWatcher = async (
  id: string
): Promise<BaseResponse<SingleUser>> => {
  return API.get<SingleUser>(`${Endpoints.USERS}/${id}`);
};

export const getWatcherWallet = async (
  id: string
): Promise<BaseResponse<IWatcherWallet>> => {
  return API.get<IWatcherWallet>(`${Endpoints.WATCHER_WALLET}/${id}`);
};

export const getWatcherStats = async (
  id: string
): Promise<BaseResponse<ISingleWatcherStats>> => {
  return API.get<ISingleWatcherStats>(`${Endpoints.WATCHER_STATS}/${id}`);
};

export const getAdsWatched = async (
  params: IPaginationParams & { userId: string }
): Promise<BaseResponse<[]>> => {
  return API.get<[]>(`${Endpoints.ADS_WATCHED}`, { params });
};

export const updateUserProfile = async ({
  id,
  ...body
}: IEditUser & { id: string }): Promise<BaseResponse<IUser>> => {
  return API.patch<Omit<IEditUser, "id">, IUser>(
    `${Endpoints.USERS}/${id}/profile`,
    body
  );
};

export const deleteUser = async ({
  id,
}: {
  id: string;
}): Promise<BaseResponse<IUser>> => {
  return API.delete<IUser>(`${Endpoints.USERS}/${id}`);
};

export const banUser = async ({
  id,
}: {
  id: string;
}): Promise<BaseResponse<IUser>> => {
  return API.delete<IUser>(`${Endpoints.USERS}/${id}/ban`);
};

export const suspendUser = async ({
  id,
}: {
  id: string;
}): Promise<BaseResponse<IUser>> => {
  return API.patch<ISuspendUser, IUser>(`${Endpoints.USERS}/${id}/suspend`);
};

export const unSuspendUser = async ({
  id,
}: {
  id: string;
}): Promise<BaseResponse<IUser>> => {
  return API.patch<ISuspendUser, IUser>(`${Endpoints.USERS}/${id}/unsuspend`);
};
