import {
  REFRESH_TOKEN_NAME,
  TOKEN_NAME,
  USER_DETAILS_LOCAL,
} from "@/constants/api";
import { approveAdmin } from "@/network/auth";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const Root = () => {
  const [isCheckAuthorization, setIsCheckAuthorization] = useState(false);
  let [params] = useSearchParams();
  const { mutateAsync: mutateApproveAdmin } = useMutation({
    mutationFn: approveAdmin,
  });

  useEffect(() => {
    const approveAdmin = async () => {
      const res = await mutateApproveAdmin({
        email: params.get("email") || "",
        token: params.get("token") || "",
      });
      if ("error" in res) {
        message.error(res.message);
        window.location.replace("/");
        return;
      } else {
        sessionStorage.setItem(TOKEN_NAME, res.data.tokens.accessToken);
        sessionStorage.setItem(
          USER_DETAILS_LOCAL,
          JSON.stringify(res.data.user)
        );
        sessionStorage.setItem(
          REFRESH_TOKEN_NAME,
          res.data.tokens.refreshToken
        );
        window.location.replace("/");
      }
    };
    if (params.get("email") && params.get("token")) {
      approveAdmin();
    } else {
      setIsCheckAuthorization(true);
    }
  }, []);

  if (!isCheckAuthorization) {
    return null;
  }

  if (!sessionStorage.getItem(TOKEN_NAME)) {
    return <Navigate to="/signin" />;
  }

  return <Navigate to="/dashboard/home" />;
};

export default Root;
