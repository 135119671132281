import { Form } from "antd";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  align-self: stretch;
  & .ant-form-item {
    margin-bottom: 0;
    width: 100%;
    & .ant-form-item-label {
      & > label.ant-form-item-required:before {
        display: none;
      }
    }
  }
`;
