import { Mail } from "@/assets/vectors";
import { StyledButton } from "@/components/Auth/Button/Button.style";
import { StyledForm } from "@/components/Auth/Form/Form.styles";
import FormHeader from "@/components/Auth/FormHeader/FormHeader";
import { StyledInput } from "@/components/Auth/Input/Input.style";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { ForgotPasswordFieldType } from "./ForgotPassword.types";
import { Form, message } from "antd";
import { required } from "@/utils/validation";
import AuthLayout from "@/layouts/AuthLayout/AuthLayout";
import { FC } from "react";
import { forgotPassword } from "@/network/auth";
import { useMutation } from "@tanstack/react-query";

const ForgotPassword: FC = () => {
  const { mutate, data, isPending } = useMutation({
    mutationFn: forgotPassword,
  });

  console.log(data);

  if (data) {
    if ("statusCode" in data) {
      message.error(data.message);
    } else {
      message.success(data.message ?? "Reset successful, check your mail");
    }
  }

  const onFinish = (values: ForgotPasswordFieldType) => {
    console.log("Success:", values);
    mutate(values);
  };

  return (
    <AuthLayout>
      <StyledForm name="forgot-password" onFinish={onFinish}>
        <StyledFlex vertical align="flex-start" gap={32} $alignSelf="stretch">
          <FormHeader
            heading="Forgot Password"
            subHeading="Fill in your details"
          />
          <StyledFlex gap={20} vertical align="stretch" $alignSelf="stretch">
            <Form.Item<ForgotPasswordFieldType>
              name="email"
              rules={[
                required("Please input your Email!"),
                {
                  type: "email",
                  message: "Please input a valid Email",
                },
              ]}
            >
              <StyledInput type="email" prefix={<Mail />} placeholder="Email" />
            </Form.Item>
          </StyledFlex>
        </StyledFlex>
        <StyledButton loading={isPending} type="primary" htmlType="submit">
          Continue
        </StyledButton>
      </StyledForm>
    </AuthLayout>
  );
};

export default ForgotPassword;
