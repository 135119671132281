import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  SearchContainer,
  RevenueTrackingFilterContainerOne,
  RevenueTrackingLayout,
  VideoContentData,
  VideoContentImg,
  VideoContentDataText,
} from "./RevenueTracking.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { Download, Search } from "@/assets/vectors";
import { ConfigProvider } from "antd";
import { revenueTrackingAntdTheme } from "./RevenueTracking.theme";
import RangePicker from "@/components/Dashboard/RangePicker/RangePicker";
import { DateTime } from "luxon";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { StyledExportButton } from "@/components/Dashboard/Button/Button.styles";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import type { ColumnsType } from "antd/es/table";
import { applyEllipsis } from "@/utils/helper-functions";
import Table from "@/components/Dashboard/Table/Table";
import { shekelsData } from "@/utils/mock-data";

interface DataType {
  key: string;
  advertisersName: string;
  shekelsConversions: number;
  revenue: number;
  adPlacements: number;
  date: string;
  videoImage: string;
  videoTitle: string;
  videoContent: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Video Content",
    key: "videoTitle",
    render: (_, { videoContent, videoImage, videoTitle }) => (
      <VideoContentData>
        <VideoContentImg src={videoImage} alt="" />
        <VideoContentDataText>
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {videoTitle}
          </StyledTypography>
          <StyledTypography
            as="p"
            $variantColor="black80"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoContent, 54)}
          </StyledTypography>
        </VideoContentDataText>
      </VideoContentData>
    ),
    width: "30%",
  },
  {
    title: "Advertisers",
    key: "advertisersName",
    render: (_, { advertisersName }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {advertisersName}
      </StyledTypography>
    ),
    width: "16%",
  },
  {
    title: "Total Revenue",
    key: "revenue",
    render: (_, { revenue }) => (
      <StyledFlex vertical gap={1}>
        <StyledTypography
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {revenue} Shekels
        </StyledTypography>
        <StyledTypography
          $variantColor="gray500"
          $variantSize="xs"
          $fontWeight={400}
        >
          ₦ 4,400 in Naira
        </StyledTypography>
      </StyledFlex>
    ),
    width: "13%",
  },
  {
    title: "Shekel Conversions",
    key: "shekelsConversions",
    render: (_, { shekelsConversions }) => (
      <StyledFlex vertical gap={1}>
        <StyledTypography
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {shekelsConversions} Shekels
        </StyledTypography>
        <StyledTypography
          $variantColor="gray500"
          $variantSize="xs"
          $fontWeight={400}
        >
          ₦ 4,400 in Naira
        </StyledTypography>
      </StyledFlex>
    ),
    width: "17%",
  },
  {
    title: "Ad Placements",
    key: "adPlacements",
    render: (_, { adPlacements }) => (
      <StyledFlex vertical gap={1}>
        <StyledTypography
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {adPlacements} Shekels
        </StyledTypography>
        <StyledTypography
          $variantColor="gray500"
          $variantSize="xs"
          $fontWeight={400}
        >
          ₦ 4,400 in Naira
        </StyledTypography>
      </StyledFlex>
    ),
    width: "14%",
  },
  {
    title: "Date ",
    key: "date",
    render: (_, { date }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {date}
      </StyledTypography>
    ),
    width: "14%",
  },
];

const RevenueTracking = () => {
  return (
    <ConfigProvider theme={revenueTrackingAntdTheme}>
      <RevenueTrackingLayout gap={32} vertical $alignSelf="stretch">
        <StyledFlex gap={4} vertical $alignSelf="stretch">
          <StyledTypography
            $variantColor="gray90"
            $variantSize="2xl"
            $fontWeight={500}
          >
            Transaction History
          </StyledTypography>
          <StyledTypography $variantColor="black80" $variantSize="sm">
            Payment Processing and Transaction History
          </StyledTypography>
        </StyledFlex>
        <StyledDivider />
        <StyledFlex gap={32} align="center" justify="space-between">
          <StyledFlex gap={16} flex="1" align="center">
            <RevenueTrackingFilterContainerOne>
              <RangePicker
                ranges={{
                  Today: [
                    DateTime.now().startOf("day"),
                    DateTime.now().endOf("day"),
                  ],
                  "This Week": [
                    DateTime.now().startOf("week").startOf("day"),
                    DateTime.now().endOf("week").endOf("day"),
                  ],
                  "This Month": [
                    DateTime.now().startOf("month").startOf("day"),
                    DateTime.now().endOf("month").endOf("day"),
                  ],
                  "This Year": [
                    DateTime.now().startOf("year").startOf("day"),
                    DateTime.now().endOf("year").endOf("day"),
                  ],
                }}
              />
            </RevenueTrackingFilterContainerOne>
          </StyledFlex>
          <SearchContainer>
            <StyledInput prefix={<Search />} placeholder="Search" />
          </SearchContainer>
          <StyledExportButton type="primary">
            <Download />
            <span>Export</span>
          </StyledExportButton>
        </StyledFlex>
        <StyledFlex vertical gap={16}>
          <StyledTypography
            $variantColor="gray90"
            $variantSize="lg"
            $fontWeight={500}
          >
            Revenue data
          </StyledTypography>
          <Table
            columns={columns}
            dataSource={shekelsData}
            isPaginated={true}
          />
        </StyledFlex>
      </RevenueTrackingLayout>
    </ConfigProvider>
  );
};

export default RevenueTracking;
