import { globalTheme } from "@/theme/theme";
import { ThemeConfig } from "antd";

export const transactionHistoryAntdTheme: ThemeConfig | undefined = {
  components: {
    Button: {
      colorPrimary: globalTheme.color.white,
      primaryColor: globalTheme.color.primary,
    },
    Select: {
      fontSize: 14,
      colorBorder: globalTheme.color.black60,
      colorText: globalTheme.color.black100,
      borderRadius: 8,
      optionSelectedColor: globalTheme.color.white,
      optionSelectedBg: globalTheme.color.primary,
      colorTextPlaceholder: globalTheme.color.black100,
    },
  },
};
