import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { Flex } from "antd";
import styled from "styled-components";

export const TribeManagementLayout = styled(StyledFlex)`
  width: 100%;
`;

export const SearchContainer = styled.div`
  width: 328px;
`;

export const StyledTableImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

export const StyledActionBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

export const StyledAddTribeActionButtonContainer = styled(Flex)`
  & > * {
    width: 200px;
  }
`;
