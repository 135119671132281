import { ConfigProvider } from "antd";
import { StyledLayout, StyledMain } from "./DashboardLayout.styles";
import { FC, useState } from "react";
import { IDashboardProps } from "./DashboardLayout.types";
import { dashboardAntdTheme } from "./DashboardLayout.theme";
import { StyledContainer } from "../Container/Container.styles";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const DashboardLayout: FC<IDashboardProps> = () => {
  const [isSidebarLoaded, setIsSidebarLoaded] = useState(false);

  return (
    <ConfigProvider theme={dashboardAntdTheme}>
      <StyledContainer>
        <StyledLayout>
          <Sidebar setIsLoaded={setIsSidebarLoaded} />
          {isSidebarLoaded ? (
            <StyledMain>
              <Outlet />
            </StyledMain>
          ) : null}
        </StyledLayout>
      </StyledContainer>
    </ConfigProvider>
  );
};

export default DashboardLayout;
