import { FC } from "react";
import { ITableProps } from "./Table.types";
import { StyledTable } from "./Table.styles";
import { ArrowLeft, ArrowRight } from "@/assets/vectors";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";

const Table: FC<ITableProps> = ({
  total,
  pageSize,
  onPaginationChange,
  isPaginated,
  ...rest
}) => {
  return (
    <StyledTable
      {...rest}
      isPaginated={isPaginated}
      pagination={
        isPaginated
          ? {
              total,
              pageSize,
              showSizeChanger: false,
              showPrevNextJumpers: true,
              onChange: (page) =>
                onPaginationChange && onPaginationChange(page),
              itemRender(_, type, element) {
                if (type === "prev") {
                  return (
                    <StyledFlex gap={8} align="center">
                      <ArrowLeft />
                      <StyledTypography
                        $variantColor="gray800"
                        $variantSize="sm"
                      >
                        Previous
                      </StyledTypography>
                    </StyledFlex>
                  );
                }
                if (type === "next") {
                  return (
                    <StyledFlex gap={8} align="center">
                      <StyledTypography
                        $variantColor="gray800"
                        $variantSize="sm"
                      >
                        Next
                      </StyledTypography>
                      <ArrowRight />
                    </StyledFlex>
                  );
                }
                if (type === "jump-next") {
                  return "...";
                }
                if (type === "jump-prev") {
                  return "...";
                }
                return element;
              },
            }
          : undefined
      }
    />
  );
};

export default Table;
