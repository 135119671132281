import { pixelToREM } from "@/utils/helper-functions";

const font = {
  fontFamily: `'Lexend', sans-serif`,
  xs: {
    fontSize: pixelToREM(12),
    lineHeight: "18px",
    letterSpacing: "normal",
  },
  sm: {
    fontSize: pixelToREM(14),
    lineHeight: "20px",
    letterSpacing: "initial",
  },
  md: {
    fontSize: pixelToREM(16),
    lineHeight: "24px",
    letterSpacing: "initial",
  },
  lg: {
    fontSize: pixelToREM(18),
    lineHeight: "normal",
    letterSpacing: "initial",
  },
  xl: {
    fontSize: pixelToREM(20),
    lineHeight: "normal",
    letterSpacing: "initial",
  },
  "2xl": {
    fontSize: pixelToREM(30),
    lineHeight: "38px",
    letterSpacing: "initial",
  },
  "3xl": {
    fontSize: pixelToREM(36),
    lineHeight: "44px",
    letterSpacing: "-0.72px",
  },
} as const;

export default font;
