import styled from "styled-components";

export const StyledCommentsList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const StyledCommentRepliesList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding-left: 48px;
`;

export const StyledComment = styled.li`
  display: flex;
  padding: 14px 8px;
  align-items: flex-start;
  gap: 7px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.gray100};
  width: 100%;
`;

export const StyledCommentReply = styled.li`
  display: flex;
  padding: 14px 8px;
  align-items: flex-start;
  gap: 7px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.gray100};
  width: 100%;
`;

export const StyledCommentImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  border: 0.5px solid #e0e0e0;
  object-fit: cover;
`;
