import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledAdProfileVideoContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 428px;
  img {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const StyledAdProfileVideoPlay = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  width: 68px;
  height: 68px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  background: rgba(255, 255, 255, 0.29);
`;

export const StyledAdProfileVideoLink = styled(Link)`
  display: flex;
  gap: 14px;
  align-items: center;
  position: absolute;
  left: 24px;
  bottom: 24px;
`;

export const StyledAdvertisersImage = styled.img`
  object-fit: cover;
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

export const StyledIngoPill = styled.p`
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.color.black50};
`;

export const StyledOptionsContainer = styled.ol`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
`;

export const StyledOptions = styled.li<{ isAnswer: boolean }>`
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: ${({ theme, isAnswer }) =>
    `1px solid ${isAnswer ? theme.color.green : theme.color.black60}`};
  background-color: ${({ theme, isAnswer }) =>
    `${isAnswer ? theme.color.green : theme.color.white}`};
  & *:nth-child(2) {
    flex-grow: 1;
  }
`;

export const StyledShekelsDetails = styled(StyledFlex)`
  background-color: ${({ theme }) => theme.color.primaryLight2};
  padding: 8px 24px;
  border-radius: 8px;
`;

export const ModalActionButtonContainer = styled(StyledFlex)`
  width: 100%;
  & > * {
    flex-grow: 1;
  }
`;
