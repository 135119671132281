import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import type { ColumnsType } from "antd/es/table";
import { AdvestisersImg } from "./TopRevenuePerformingAds.styles";
import Table from "@/components/Dashboard/Table/Table";
import Eva from "@/assets/images/eva.jpeg";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { PAGINATION_LIMIT, QUERY_KEY_TOP_REVENUE_ADS } from "@/constants/api";
import { getTopRevenueAds } from "@/network/analytics";
import { DateTime } from "luxon";

interface DataType {
  key: string;
  adImg: string;
  adName: string;
  advertiser: string;
  budget: number;
  revenue: number;
  views: number;
  disbursement: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Video Content",
    key: "videoTitle",
    render: (_, { adImg, adName }) => (
      <StyledFlex gap="12px" align="center">
        <AdvestisersImg src={adImg} alt="" />
        <StyledTypography
          as="p"
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {adName}
        </StyledTypography>
      </StyledFlex>
    ),
    width: "40%",
  },
  {
    title: "Advertiser",
    key: "advertiser",
    render: (_, { advertiser }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {advertiser}
      </StyledTypography>
    ),
    width: "10%",
  },
  {
    title: "Ad Budget",
    key: "budget",
    render: (_, { budget }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {budget}
      </StyledTypography>
    ),
    width: "11%",
  },
  {
    title: "Ad Revenue",
    key: "revenue",
    render: (_, { revenue }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {revenue}
      </StyledTypography>
    ),
    width: "12%",
  },
  {
    title: "Views Accrued",
    key: "views",
    render: (_, { views }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {views}
      </StyledTypography>
    ),
    width: "14%",
  },
  {
    title: "Shekels Disbursed",
    key: "disbursement",
    render: (_, { disbursement }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {disbursement}
      </StyledTypography>
    ),
    width: "13%",
  },
];

const TopRevenuePerformingAds = () => {
  const [page, setPage] = useState<number>(1);
  const { data: topRevenueAds, isPending: isPendingTopRevenueAds } = useQuery({
    queryKey: [QUERY_KEY_TOP_REVENUE_ADS, page],
    queryFn: async () =>
      getTopRevenueAds({
        limit: PAGINATION_LIMIT,
        page: page,
      }),
  });

  if (topRevenueAds && "error" in topRevenueAds) {
    return null;
  }

  return (
    <div>
      <StyledTypography
        $variantColor="black100"
        $variantSize="lg"
        $fontWeight={500}
        $mb={"26px"}
        as="p"
      >
        Top Revenue Performing Ads
      </StyledTypography>
      <Table
        pageSize={PAGINATION_LIMIT}
        onPaginationChange={(page) => {
          setPage(page);
        }}
        columns={columns}
        dataSource={
          topRevenueAds && topRevenueAds.data.length
            ? topRevenueAds.data.map(
                ({
                  advert: {
                    id,
                    createdAt,
                    user: {
                      profile: { firstName, lastName },
                    },
                    metadata,
                    thumbnail,
                    title,
                  },
                }) => ({
                  key: id,
                  adImg: thumbnail?.publicURL ?? Eva,
                  views: metadata?.totalViews ?? 0,
                  budget: metadata?.totalSpent ?? 0,
                  revenue: metadata?.totalSpent ?? 0,
                  disbursement: 0,
                  adName: title,
                  date: DateTime.fromISO(createdAt).toFormat("MMM dd, yyyy"),
                  advertiser: `${firstName ?? "-"} ${lastName ?? "-"}`,
                })
              )
            : []
        }
        isPaginated={true}
        total={(topRevenueAds && topRevenueAds.totalDocuments) ?? 0}
        loading={isPendingTopRevenueAds}
      />
    </div>
  );
};

export default TopRevenuePerformingAds;
