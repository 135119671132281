import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  StyledCommentImage,
  StyledCommentRepliesList,
  StyledCommentReply,
  StyledCommentsList,
} from "./AdComments.styles";
import { FC, useMemo } from "react";
import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  QUERY_KEY_ADVERTISEMENT,
  QUERY_KEY_AD_COMMENTS,
  QUERY_KEY_AD_COMMENT_REPLIES,
} from "@/constants/api";
import {
  deleteCommentReply,
  getAdvertCommentReplies,
} from "@/network/advertisement";
import { formatISODateString } from "@/utils/helper-functions";
import { Spin } from "antd";

const DeleteCommentReply: FC<{
  adId?: string;
  commentId: string;
  replyId: number;
  queryClient: QueryClient;
}> = ({ commentId, adId, queryClient, replyId }) => {
  const {
    mutateAsync: deleteCommentReplyMutation,
    isPending: isPendingDeleteCommentReply,
  } = useMutation({
    mutationFn: deleteCommentReply,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_AD_COMMENT_REPLIES, adId, commentId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISEMENT, adId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_AD_COMMENTS, adId],
      });
    },
  });

  return (
    <button
      disabled={isPendingDeleteCommentReply}
      onClick={async () => {
        if (adId && commentId) {
          const res = await deleteCommentReplyMutation({
            adId,
            commentId: `${commentId}`,
            replyId: `${replyId}`,
          });
          console.log(res);
        }
      }}
    >
      <StyledTypography
        onClick={() => {}}
        $variantColor="red"
        $variantSize="xs"
      >
        {isPendingDeleteCommentReply ? "Deleting..." : "Delete"}
      </StyledTypography>
    </button>
  );
};

const AdCommentReplies: FC<{ adId?: string; commentId: string }> = ({
  commentId,
  adId,
}) => {
  // Access the client
  const queryClient = useQueryClient();

  const {
    data: comments,
    fetchNextPage: fetchNextPageCommentReplies,
    hasNextPage: hasNextPageCommentReplies,
    isFetching: isFetchingCommentReplies,
    isLoading: isLoadingCommentReplies,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY_AD_COMMENT_REPLIES, adId, commentId],
    queryFn: ({ pageParam }) =>
      adId
        ? getAdvertCommentReplies(
            { adId, commentId },
            {
              limit: 10,
              page: pageParam as number,
            }
          )
        : undefined,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage &&
      "data" in lastPage &&
      lastPage.currentPage < lastPage.totalPages
        ? lastPage.currentPage + 1
        : null,
  });

  const flattenedCommentReplies = useMemo(
    () =>
      comments
        ? comments?.pages?.flatMap((page) =>
            page && "data" in page ? page.data : []
          )
        : [],
    [comments]
  );

  return (
    <StyledFlex vertical $alignSelf="stretch" align="flex-start" gap={24}>
      <StyledCommentRepliesList>
        {flattenedCommentReplies.map(
          ({
            comment,
            user: {
              profile: { avatar, firstName, lastName, username },
            },
            createdAt,
            id,
          }) => (
            <StyledCommentReply key={id}>
              <StyledCommentImage src={avatar?.publicURL ?? ""} alt="" />
              <StyledFlex vertical $alignSelf="stretch" gap={8}>
                <StyledFlex $alignSelf="stretch" gap={4}>
                  <StyledTypography
                    $variantColor="black100"
                    $variantSize="sm"
                    $fontWeight={600}
                  >
                    {firstName} {lastName}
                  </StyledTypography>
                  <StyledTypography $variantColor="black80" $variantSize="xs">
                    @{username}
                  </StyledTypography>
                </StyledFlex>
                <StyledTypography $variantColor="black100" $variantSize="sm">
                  {comment}
                </StyledTypography>
                <StyledFlex $alignSelf="stretch" gap={8}>
                  <StyledTypography $variantColor="black80" $variantSize="xs">
                    {formatISODateString(createdAt)}
                  </StyledTypography>
                  <DeleteCommentReply
                    queryClient={queryClient}
                    adId={adId}
                    commentId={commentId}
                    replyId={id}
                  />
                </StyledFlex>
              </StyledFlex>
            </StyledCommentReply>
          )
        )}
        {hasNextPageCommentReplies ? (
          <button
            onClick={() => {
              fetchNextPageCommentReplies();
            }}
          >
            <StyledTypography
              $variantColor="primary"
              $variantSize="sm"
              $fontWeight={500}
              $underline="underline"
            >
              Show more
            </StyledTypography>
          </button>
        ) : null}
        {isLoadingCommentReplies || isFetchingCommentReplies ? <Spin /> : null}
      </StyledCommentRepliesList>
    </StyledFlex>
  );
};

export default AdCommentReplies;
