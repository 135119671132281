import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";
import CSS from "csstype";

export const StyledPieChartCategoryContainer = styled(StyledFlex)`
  list-style: none;
`;

export const StyledPieChartCategoryColor = styled.div<{
  $bgColor: CSS.Property.BackgroundColor;
}>`
  background-color: ${({ $bgColor }) => $bgColor};
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;
