// import { DatePicker } from "antd";
import luxonGenerateConfig from "@/utils/luxonGenerateConfig";
import generatePicker from "antd/es/date-picker/generatePicker";
import { DateTime } from "luxon";
import styled from "styled-components";

const DatePicker = generatePicker<DateTime>(luxonGenerateConfig);

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  height: 48px;
  /* &.ant-select-single .ant-select-selector {
    padding: 0 16px;
  }
  & .ant-select-arrow {
    inset-inline-end: 16px;
  } */
`;
