import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const SingleAdLayout = styled(StyledFlex)`
  width: 100%;
`;

export const SingleAdMain = styled(StyledFlex)`
  width: 100%;
`;
