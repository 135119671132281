import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { HomeLayout } from "./Home.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledCard } from "@/components/Dashboard/Card/Card.styles";
import { ConfigProvider } from "antd";
import { homeAntdTheme } from "./Home.theme";
import AreaChart from "@/components/Dashboard/AreaChart/AreaChart";
import { DateTime } from "luxon";
import TopAdPerformance from "@/components/Dashboard/Analytics/TopAdPerformance/TopAdPerformance";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import {
  QUERY_KEY_AD_PERFORMING_METRICS,
  QUERY_KEY_METRICS,
  QUERY_KEY_REVENUE_BAR_CHART,
} from "@/constants/api";
import { useQuery } from "@tanstack/react-query";
import {
  getAdPerformingMetrics,
  getMetrics,
  getRevenueBarChart,
} from "@/network/analytics";
import { useState } from "react";
import { ISODateString } from "@/types/global";
import AnalyticsFilter from "@/components/Dashboard/Analytics/AnalyticsFilter/AnalyticsFilter";

const Home = () => {
  const [dateRange, setDateRange] = useState<[string, string]>([
    DateTime.now().minus({ month: 1 }).startOf("day").toISO(),
    DateTime.now().endOf("day").toISO(),
  ]);
  const { data: adPerformingMetricsData } = useQuery({
    queryKey: [QUERY_KEY_AD_PERFORMING_METRICS, dateRange],
    queryFn: async () =>
      getAdPerformingMetrics({
        startDate: dateRange[0] as ISODateString,
        endDate: dateRange[1] as ISODateString,
      }),
  });
  const { data: metricsData } = useQuery({
    queryKey: [QUERY_KEY_METRICS, dateRange],
    queryFn: async () =>
      getMetrics({
        startDate: dateRange[0] as ISODateString,
        endDate: dateRange[1] as ISODateString,
      }),
  });
  const { data: revenueChartData } = useQuery({
    queryKey: [QUERY_KEY_REVENUE_BAR_CHART, dateRange],
    queryFn: async () =>
      getRevenueBarChart({
        startDate: dateRange[0] as ISODateString,
        endDate: dateRange[1] as ISODateString,
      }),
  });

  const metrics =
    metricsData && "error" in metricsData ? null : metricsData?.data;
  const adPerformingMetrics =
    adPerformingMetricsData && "error" in adPerformingMetricsData
      ? null
      : adPerformingMetricsData?.data;
  const revenueChart =
    revenueChartData && "error" in revenueChartData
      ? null
      : revenueChartData?.data;

  return (
    <ConfigProvider theme={homeAntdTheme}>
      <HomeLayout gap={32} vertical $alignSelf="stretch">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Ad Analytics
        </StyledTypography>
        <AnalyticsFilter dateRange={dateRange} setDateRange={setDateRange} />
        <StyledFlex align="stretch" gap={24} $alignSelf="stretch">
          {[
            {
              title: "Total Revenue",
              value: `₦${metrics?.adRevenue?.toLocaleString() ?? 0}`,
            },
            {
              title: "Total number of Advertisers",
              value: `${
                adPerformingMetrics?.totalAdvertisers?.toLocaleString() ?? 0
              }`,
            },
            {
              title: "Total Number of Watchers",
              value: `${
                adPerformingMetrics?.totalWatchers?.toLocaleString() ?? 0
              }`,
            },
            {
              title: "Views",
              value: `${
                adPerformingMetrics?.totalViewedAds?.toLocaleString() ?? 0
              }`,
            },
          ].map((item) => (
            <StyledFlex
              key={item.title}
              flex={"1 1 0"}
              $alignSelf="stretch"
              align="center"
            >
              <StyledCard>
                <StyledFlex gap={8} $alignSelf="stretch" vertical>
                  <StyledTypography
                    $variantColor="black90"
                    $variantSize="sm"
                    $fontWeight={500}
                  >
                    {item.title}
                  </StyledTypography>
                  <StyledTypography
                    $variantColor="black100"
                    $variantSize="2xl"
                    $fontWeight={500}
                  >
                    {item.value}
                  </StyledTypography>
                </StyledFlex>
              </StyledCard>
            </StyledFlex>
          ))}
        </StyledFlex>
        <div>
          <StyledDivider $mb="20px" />
          <StyledFlex vertical gap={"25px"}>
            <StyledFlex vertical $alignSelf="stretch">
              <StyledTypography
                $variantColor="black100"
                $variantSize="lg"
                $fontWeight={500}
                $mb={"4px"}
              >
                Revenue
              </StyledTypography>
              <StyledTypography
                $variantColor="black80"
                $variantSize="sm"
                $fontWeight={500}
              >
                Compare generated revenue over time.
              </StyledTypography>
            </StyledFlex>
            <AreaChart
              series={[
                {
                  name: "Revenue",
                  data: revenueChart?.length
                    ? revenueChart?.map((chartData) => ({
                        x: chartData.createdAt,
                        y: chartData._sum.amount,
                      }))
                    : [],
                },
              ]}
              height={320}
            />
          </StyledFlex>
        </div>
        <TopAdPerformance />
      </HomeLayout>
    </ConfigProvider>
  );
};

export default Home;
