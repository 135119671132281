import {
  IPaginationMeta,
  IPaginationParams,
  ISODateString,
} from "@/types/global";
import API, { BaseResponse } from "./API";
import Endpoints from "./endpoints";
import { IAdvert } from "@/types/advert";

interface IMetricsParams {
  startDate?: ISODateString;
  endDate?: ISODateString;
  tribeMemberId?: string;
  advertiserId?: string;
}

interface ITopPerformingAdsResponse {
  id: number;
  name: string;
  description: string | null;
  totalPosts: number;
  totalAdvertisements: number;
  uuid: string | null;
  status: string;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  tribeImageId: number | null;
  totalComments: number;
  totalViews: number;
  totalLikes: number;
  shekelsEarned: number;
}

interface IAdMetrics extends IAdvert {}

interface IWatchersResponse {
  advert: Pick<IAdMetrics, "advert">;
  id: number;
  userId: number;
  liked: boolean;
  comment: string;
  advertisementId: number;
  deleted: boolean;
  watched: boolean;
  shekelsEarned: number;
  viewed: boolean;
  verificationMessage: "";
  watchedDate: null;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  user: Pick<IAdMetrics, "advert">["advert"]["user"];
}

interface AdPerformingMetricsResponse {
  totalAdvertisers: number;
  totalWatchers: number;
  totalViewedAds: number;
  totalWatchersWhoEarned: number;
}

interface RevenuBarChartResponse {
  _sum: {
    amount: number;
  };
  actionType: string;
  createdAt: string;
}

interface TibePieChartResponse {
  tribeId: string;
  totalAmount: number;
}

interface IShekelsMetricsResponse {
  id: number;
  amount: number;
  actionType: "SHEKELS_EARNED";
  userId: number;
  deleted: boolean;
  advertisementId: number;
  transactionId: number;
  commentId: null | number;
  postId: null | number;
  createdAt: string;
  updatedAt: string;
  user: {
    profile: {
      username: string;
      firstName: string;
      lastName: string;
      tribes: {
        advertiserInfoId: null;
        id: 699;
        tribeId: 26;
        userProfileId: 1085;
      }[];
    };
  };
  advertisement: {
    user: {
      profile: {
        username: string;
        firstName: string;
        lastName: string;
        gender: string;
        tribes: {
          advertiserInfoId: null;
          id: 699;
          tribeId: 26;
          userProfileId: 1085;
        }[];
      };
    };
  };
}

interface MetricsResponse {
  adRevenue: number;
  adBudgetAllocation: number;
  shekelsEarned: number;
  slingBalance: number;
}

interface ITopWatchersAgeResponse {
  ageGroup: string;
  count: number;
}

interface ITopWatchersGenderResponse {
  gender: "male" | "female";
  count: number;
}

export const getMetrics = async (
  params: IMetricsParams
): Promise<BaseResponse<MetricsResponse>> => {
  return API.get<MetricsResponse>(Endpoints.METRICS, { params });
};

export const getShekelsMetrics = async (
  params: IMetricsParams &
    IPaginationParams & {
      search?: string;
    }
): Promise<BaseResponse<IShekelsMetricsResponse[], IPaginationMeta>> => {
  return API.get<IShekelsMetricsResponse[], IPaginationMeta>(
    Endpoints.SHEKEL_METRICS,
    {
      params,
    }
  );
};

export const getAdPerformingMetrics = async (
  params: IMetricsParams
): Promise<BaseResponse<AdPerformingMetricsResponse>> => {
  return API.get<AdPerformingMetricsResponse>(Endpoints.AD_PERFORMING_METRICS, {
    params,
  });
};

export const getRevenueBarChart = async (
  params: IMetricsParams
): Promise<BaseResponse<RevenuBarChartResponse[]>> => {
  return API.get<RevenuBarChartResponse[]>(Endpoints.REVENUE_BAR_CHART, {
    params,
  });
};

export const getTribePieChart = async (
  params: IMetricsParams
): Promise<BaseResponse<TibePieChartResponse[]>> => {
  return API.get<TibePieChartResponse[]>(Endpoints.TRIBE_REVENUE_PIE_CHART, {
    params,
  });
};

export const getTopPerformingAds = async (
  params: IPaginationParams
): Promise<BaseResponse<IAdMetrics[], IPaginationMeta>> => {
  return API.get<IAdMetrics[], IPaginationMeta>(Endpoints.TOP_PERFORMING_ADS, {
    params,
  });
};

export const getTopRevenueAds = async (
  params: IPaginationParams
): Promise<BaseResponse<IAdMetrics[], IPaginationMeta>> => {
  return API.get<IAdMetrics[], IPaginationMeta>(Endpoints.TOP_REVENUE_ADS, {
    params,
  });
};

export const getTopPerformingTribes = async (): Promise<
  BaseResponse<ITopPerformingAdsResponse[]>
> => {
  return API.get<ITopPerformingAdsResponse[]>(Endpoints.TOP_PERFORMING_TRIBES);
};

export const getTopWatchers = async (
  params: IPaginationParams
): Promise<BaseResponse<IWatchersResponse[], IPaginationMeta>> => {
  return API.get<IWatchersResponse[], IPaginationMeta>(Endpoints.TOP_WATCHERS, {
    params,
  });
};

export const getTopWatchersGender = async (): Promise<
  BaseResponse<ITopWatchersGenderResponse[]>
> => {
  return API.get<ITopWatchersGenderResponse[]>(Endpoints.TOP_WATCHERS_GENDER);
};

export const getTopWatchersAge = async (): Promise<
  BaseResponse<ITopWatchersAgeResponse[]>
> => {
  return API.get<ITopWatchersAgeResponse[]>(Endpoints.TOP_WATCHERS_AGE);
};

export const getTopViewersLocation = async (): Promise<BaseResponse<[]>> => {
  return API.get<[]>(Endpoints.TOP_VIEWERS_LOCATION);
};
