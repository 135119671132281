import { globalTheme } from "@/theme/theme";
import { ThemeConfig } from "antd";

export const settingsAntdTheme: ThemeConfig | undefined = {
  // components: {
  //   Button: {
  //     colorPrimary: globalTheme.color.white,
  //     primaryColor: globalTheme.color.primary,
  //   },
  // },
};
