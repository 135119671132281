import { pixelToREM } from "@/utils/helper-functions";
import styled from "styled-components";
import { Theme } from "@/types/theme";

export const StyledPill = styled.p<{
  $bgColor: keyof Theme["color"];
  $color: keyof Theme["color"];
}>`
  display: inline-flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: ${({ $bgColor, theme }) => theme.color[$bgColor]};
  color: ${({ $color, theme }) => theme.color[$color]};
  text-align: center;
  font-size: ${pixelToREM(12)};
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;
