import { FC } from "react";
import { StyledModal } from "./Modal.styles";
import { IModalProps } from "./Modal.types";

const Modal: FC<IModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  children,
  footer,
  heading,
  width,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <StyledModal
      title={heading}
      open={isModalOpen}
      onCancel={handleCancel}
      centered={true}
      closeIcon={null}
      footer={footer}
      width={width ?? 549}
    >
      {children}
    </StyledModal>
  );
};

export default Modal;
