import { Select } from "antd";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  height: 48px;
  &.ant-select-single .ant-select-selector {
    padding: 0 16px;
  }
  & .ant-select-arrow {
    inset-inline-end: 16px;
  }
`;
