import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  ModalActionButtonContainer,
  StyledAdProfileVideoContainer,
  StyledAdProfileVideoLink,
  StyledAdProfileVideoPlay,
  StyledAdvertisersImage,
  StyledIngoPill,
  StyledOptions,
  StyledOptionsContainer,
  StyledShekelsDetails,
} from "./AdProfile.styles";
import { Check, Comment, Like, Link, Play, View } from "@/assets/vectors";
import CocaCola from "@/assets/images/coca-cola.png";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";
import Modal from "@/components/Dashboard/Modal/Modal";
import { FC, useState } from "react";
import { IAdProfileProps } from "./AdProfile.types";
import { abbreviateNumberToK } from "@/utils/helper-functions";

const MATCH_ANSWER = {
  OPTION_ONE: 0,
  OPTION_TWO: 1,
  OPTION_THREE: 2,
  OPTION_FOUR: 3,
};

const AdProfile: FC<IAdProfileProps> = ({
  advertiser,
  comments,
  description,
  fullName,
  likes,
  thumbnail,
  title,
  videoUrl,
  views,
  questions,
  profilePicture,
}) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
    setQuestionIndex(0);
  };

  return (
    <>
      {/* modal starts */}
      {questions?.length ? (
        <Modal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          heading="Quiz Question"
          footer={
            <ModalActionButtonContainer justify="flex-end">
              <StyledButton
                onClick={() =>
                  questionIndex !== 0
                    ? setQuestionIndex((prevIndex) => prevIndex - 1)
                    : hideModal()
                }
                type="default"
              >
                {questionIndex === 0 ? "Cancel" : "Back"}
              </StyledButton>
              <StyledButton
                onClick={() =>
                  questionIndex < questions.length - 1
                    ? setQuestionIndex((prevIndex) => prevIndex + 1)
                    : hideModal()
                }
                type="primary"
              >
                {questionIndex < questions.length - 1 ? "Next" : "Finish"}
              </StyledButton>
            </ModalActionButtonContainer>
          }
        >
          <StyledFlex vertical $alignSelf="stretch" gap={24}>
            <StyledFlex vertical $alignSelf="stretch" gap={16}>
              <StyledTypography $variantColor="black100" $variantSize="md">
                Question {questionIndex + 1} of {questions.length}
              </StyledTypography>
              <StyledTypography $variantColor="primary" $variantSize="md">
                {questions[questionIndex].question}
              </StyledTypography>
            </StyledFlex>
            <StyledOptionsContainer>
              {questions[questionIndex].options.map((item, index) => (
                <StyledOptions
                  key={index}
                  isAnswer={
                    MATCH_ANSWER[questions[questionIndex].answer] === index
                  }
                >
                  <StyledTypography
                    $variantColor={
                      MATCH_ANSWER[questions[questionIndex].answer] === index
                        ? "white"
                        : "black100"
                    }
                    $variantSize="md"
                    as="p"
                  >
                    {String.fromCharCode("A".charCodeAt(0) - 1 + (index + 1))}.
                  </StyledTypography>
                  <StyledTypography
                    $variantColor={
                      MATCH_ANSWER[questions[questionIndex].answer] === index
                        ? "white"
                        : "black100"
                    }
                    $variantSize="md"
                    as="p"
                  >
                    {item}
                  </StyledTypography>
                  {MATCH_ANSWER[questions[questionIndex].answer] === index ? (
                    <Check />
                  ) : null}
                </StyledOptions>
              ))}
            </StyledOptionsContainer>
          </StyledFlex>
        </Modal>
      ) : null}
      <StyledFlex vertical $alignSelf="stretch" gap={20}>
        <StyledFlex vertical $alignSelf="stretch" gap={16}>
          <StyledAdProfileVideoContainer>
            <img src={thumbnail} alt="" />
            <StyledAdProfileVideoPlay>
              <Play />
            </StyledAdProfileVideoPlay>
            <StyledAdProfileVideoLink to={videoUrl} target="_blank">
              <StyledTypography $variantColor="white" $variantSize="xl">
                The URL link
              </StyledTypography>
              <Link />
            </StyledAdProfileVideoLink>
          </StyledAdProfileVideoContainer>
          <StyledFlex vertical $alignSelf="stretch" gap={8}>
            <StyledTypography
              $variantColor="black100"
              $variantSize="xl"
              $fontWeight={500}
            >
              {title}
            </StyledTypography>
            <StyledTypography $variantColor="black80" $variantSize="md">
              {description}
            </StyledTypography>
          </StyledFlex>
        </StyledFlex>
        <StyledFlex gap={20} justify="space-between" wrap="wrap">
          <StyledFlex gap={20} wrap="wrap">
            <StyledFlex gap={8} align="center">
              <StyledAdvertisersImage src={profilePicture} alt="" />
              <StyledFlex vertical gap={4}>
                <StyledTypography
                  $variantColor="black100"
                  $variantSize="md"
                  $fontWeight={500}
                >
                  {fullName}
                </StyledTypography>
                <StyledTypography $variantColor="black90" $variantSize="xs">
                  @{advertiser}
                </StyledTypography>
              </StyledFlex>
            </StyledFlex>
            <StyledFlex gap={4} wrap="wrap" align="center">
              {[
                {
                  name: "Likes",
                  count: abbreviateNumberToK(likes),
                  icon: <Like />,
                },
                {
                  name: "Comments",
                  count: abbreviateNumberToK(comments),
                  icon: <Comment />,
                },
                {
                  name: "Views",
                  count: abbreviateNumberToK(views),
                  icon: <View />,
                },
              ].map((item) => (
                <StyledIngoPill key={item.name}>
                  {item.icon}
                  <StyledTypography $variantColor="black100" $variantSize="sm">
                    {item.count} {item.name}
                  </StyledTypography>
                </StyledIngoPill>
              ))}
            </StyledFlex>
          </StyledFlex>
          <button onClick={showModal}>
            <StyledTypography
              $variantColor="primary"
              $variantSize="sm"
              $fontWeight={500}
              $underline="underline"
            >
              View questions
            </StyledTypography>
          </button>
        </StyledFlex>
        <StyledShekelsDetails
          gap={20}
          justify="space-between"
          align="center"
          wrap="wrap"
        >
          <StyledFlex align="center" gap={8} wrap="wrap">
            <StyledTypography $variantColor={"white"} $variantSize="md" as="p">
              Budget Amount in Shekels:
            </StyledTypography>
            <StyledTypography
              $variantColor={"white"}
              $variantSize="xl"
              $fontWeight={500}
              as="p"
            >
              2,000
            </StyledTypography>
            <StyledTypography
              $variantColor={"primaryLight"}
              $variantSize="xs"
              $fontWeight={500}
              as="p"
            >
              (₦400,000.00)
            </StyledTypography>
          </StyledFlex>
          <StyledFlex align="center" gap={8} wrap="wrap">
            <StyledTypography $variantColor={"white"} $variantSize="md" as="p">
              Shekels allocation per watcher:
            </StyledTypography>
            <StyledTypography
              $variantColor={"white"}
              $variantSize="xl"
              $fontWeight={500}
              as="p"
            >
              10
            </StyledTypography>
            <StyledTypography
              $variantColor={"primaryLight"}
              $variantSize="xs"
              $fontWeight={500}
              as="p"
            >
              (₦2,000.00)
            </StyledTypography>
          </StyledFlex>
        </StyledShekelsDetails>
      </StyledFlex>
    </>
  );
};

export default AdProfile;
