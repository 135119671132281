import { Modal } from "antd";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    border-radius: 12px;
    box-shadow: none;
    background-color: ${(props) => props.theme.color.white};
  }
  .ant-modal-header {
    padding: 32px 32px 0 32px;
    margin-bottom: 38px;
  }
  .ant-modal-body {
    border-bottom: ${(props) => `1px solid ${props.theme.color.black50}`};
    padding: 0 32px 44px 32px;
    padding-bottom: 44px;
  }
  .ant-modal-footer {
    padding: 0 32px 20px 32px;
    margin-top: 12px;
  }
`;
