import styled from "styled-components";

export const VideoContentData = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
`;

export const VideoContentDataText = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: start;
`;

export const VideoContentImg = styled.img`
  object-fit: cover;
  width: 88px;
  height: 73px;
`;
