import instance from "./axios";
import { isPlainObject } from "@/utils/helper-functions";
import { AxiosRequestConfig, AxiosResponse } from "axios";

type ErrorResponse = {
  statusCode: number;
  message: string;
  error: string;
};

export type SuccessResponse<T = unknown, E = unknown> = {
  message: string;
  status: boolean;
  data: T;
} & E;

export type BaseResponse<T = unknown, E = unknown> =
  | ErrorResponse
  | SuccessResponse<T, E>;

class API {
  static post = async <D, T, E = unknown>(
    endpoint: string,
    data: D,
    config?: AxiosRequestConfig<D>
  ): Promise<BaseResponse<T, E>> => {
    const result = await instance.post<
      SuccessResponse<T, E> | ErrorResponse,
      AxiosResponse<SuccessResponse<T, E> | ErrorResponse>,
      D
    >(endpoint, data, config);
    if (!result || !isPlainObject(result))
      return {
        error: "unknown-error",
        message: "Something went wrong",
        statusCode: 0,
      };
    return result.data;
  };

  static patch = async <D, T, E = unknown>(
    endpoint: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<BaseResponse<T, E>> => {
    const result = await instance.patch<
      SuccessResponse<T, E> | ErrorResponse,
      AxiosResponse<SuccessResponse<T, E> | ErrorResponse>,
      D
    >(endpoint, data, config);
    if (!result || !isPlainObject(result))
      return {
        error: "unknown-error",
        message: "Something went wrong",
        statusCode: 0,
      };
    return result.data;
  };

  static put = async <D, T, E = unknown>(
    endpoint: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<BaseResponse<T, E>> => {
    const result = await instance.put<
      SuccessResponse<T, E> | ErrorResponse,
      AxiosResponse<SuccessResponse<T, E> | ErrorResponse>,
      D
    >(endpoint, data, config);
    if (!result || !isPlainObject(result))
      return {
        error: "unknown-error",
        message: "Something went wrong",
        statusCode: 0,
      };
    return result.data;
  };

  static get = async <T, E = unknown>(
    endpoint: string,
    config?: AxiosRequestConfig
  ): Promise<BaseResponse<T, E>> => {
    const result = await instance.get<SuccessResponse<T, E> | ErrorResponse>(
      endpoint,
      config
    );
    if (!result || !isPlainObject(result))
      return {
        error: "unknown-error",
        message: "Something went wrong",
        statusCode: 0,
      };
    return result.data;
  };

  static delete = async <T, E = unknown>(
    endpoint: string
  ): Promise<BaseResponse<T, E>> => {
    const result = await instance.delete<SuccessResponse<T, E> | ErrorResponse>(
      endpoint
    );
    if (!result || !isPlainObject(result))
      return {
        error: "unknown-error",
        message: "Something went wrong",
        statusCode: 0,
      };
    return result.data;
  };
}

export default API;
