import { globalTheme } from "@/theme/theme";
import AdsGif from "@/assets/images/ads-gif.gif";
import Eva from "@/assets/images/eva.jpeg";
import { Comment, Like, View } from "@/assets/vectors";

export const tribePercentage = [
  {
    color: globalTheme.color.secondary,
    label: "Hospitality",
    percent: 21,
  },
  {
    color: globalTheme.color.black100,
    label: "Banking",
    percent: 17,
  },
  {
    color: globalTheme.color.primary,
    label: "Sports",
    percent: 17,
  },
  {
    color: globalTheme.color.black60,
    label: "Telecom",
    percent: 5,
  },
  {
    color: globalTheme.color.black50,
    label: "Entertainment",
    percent: 28,
  },
  {
    color: globalTheme.color.black80,
    label: "Betting",
    percent: 12,
  },
];

export const viewersGenderPercent = [
  {
    color: globalTheme.color.secondary,
    label: "Male",
    percent: 61,
  },
  {
    color: globalTheme.color.primary,
    label: "Female",
    percent: 39,
  },
];

export const topPerformingAd = [
  {
    key: "0",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Health",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "1",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Finance",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "2",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Music",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
];

export const topRevenuePerformingAds = [
  {
    key: "0",
    adImg: Eva,
    adName: "Eva bottling Company",
    budget: "₦1,234",
    revenue: "₦523",
    views: "200k",
    disbursement: "134",
  },
  {
    key: "1",
    adImg: Eva,
    adName: "Eva bottling Company",
    budget: "₦1,234",
    revenue: "₦523",
    views: "200k",
    disbursement: "100",
  },
  {
    key: "2",
    adImg: Eva,
    adName: "Eva bottling Company",
    budget: "₦1,234",
    revenue: "₦523",
    views: "200k",
    disbursement: "92",
  },
];

export const totalAds = [
  {
    key: "0",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Health",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "1",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Finance",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "2",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Music",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "3",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Health",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "4",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Finance",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "5",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Music",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "6",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Health",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "7",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Finance",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "8",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Music",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
  {
    key: "9",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    view: "32k",
    likes: "32k",
    comments: "200",
    tribe: "Music",
    date: "Nov 24, 2023",
    advertiser: "Coca-Cola",
  },
];

export const adMetrics = [
  {
    name: "Likes",
    count: "2,070",
    icon: <Like />,
  },
  {
    name: "Comments",
    count: "75",
    icon: <Comment />,
  },
  {
    name: "Views",
    count: "35.4k",
    icon: <View />,
  },
];

export const questions = [
  {
    question: `What is the key product feature highlighted in the ad "Explore the Galaxy with Cosmic Adventures"?`,
    choices: [
      "High-tech Telescopes",
      "Astronaut-Approved Spacesuits",
      "Both A and B",
    ],
    answerIndex: 1,
  },
  {
    question: `What is the key product feature highlighted in the ad "Explore the Galaxy with Cosmic Adventures"?`,
    choices: [
      "High-tech Telescopes",
      "Astronaut-Approved Spacesuits",
      "Both A and B",
    ],
    answerIndex: 1,
  },
];

export const comments = [
  {
    image: AdsGif,
    name: "Nora Ojukwu",
    tag: "noraojukwu",
    comment: "thanks for posting! I only drink Eva",
    day: "3d",
  },
  {
    image: AdsGif,
    name: "Sola Eze",
    tag: "shollye_",
    comment:
      "Done freelance work for this brand in the past but this feels especially out of taste.",
    day: "3d",
  },
  {
    image: AdsGif,
    name: "Obi Nwachukwu",
    tag: "obithegreat",
    comment: "Revolutionary😂",
    day: "3d",
  },
  {
    image: AdsGif,
    name: "Victoria Teju",
    tag: "teju4u",
    comment: "I just got my first shekel watching this 🥳",
    day: "3d",
  },
  {
    image: AdsGif,
    name: "Bidemi Quadri",
    tag: "quadrib",
    comment: "This is the most advertising thing I’ve seen in awhile.",
    day: "3d",
  },
  {
    image: AdsGif,
    name: "Aisha Momoh",
    tag: "yeesha",
    comment: "The photography and color is wonderful!!!",
    day: "3d",
  },
];

export const activeAdvertisers = [
  {
    key: "0",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "134",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
  {
    key: "1",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "100",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
  {
    key: "2",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "92",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
  {
    key: "3",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "92",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
  {
    key: "4",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "92",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
  {
    key: "5",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "92",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
  {
    key: "6",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "92",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
  {
    key: "7",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "92",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
  {
    key: "8",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "92",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
  {
    key: "9",
    adImg: Eva,
    adName: "Eva bottling Company",
    uploadedAds: "92",
    followers: "456k",
    views: "200k",
    date: "Nov 24, 2023",
  },
];

export const inActiveAdvertisers = [
  {
    key: "0",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Banned",
    date: "Nov 24, 2023",
    type: "Brand",
  },
  {
    key: "1",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Suspended",
    date: "Nov 24, 2023",
    type: "Agency",
  },
  {
    key: "2",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Banned",
    date: "Nov 24, 2023",
    type: "Brand",
  },
  {
    key: "3",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Suspended",
    date: "Nov 24, 2023",
    type: "Agency",
  },
  {
    key: "4",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Banned",
    date: "Nov 24, 2023",
    type: "Brand",
  },
  {
    key: "5",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Suspended",
    date: "Nov 24, 2023",
    type: "Agency",
  },
  {
    key: "6",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Banned",
    date: "Nov 24, 2023",
    type: "Brand",
  },
  {
    key: "7",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Suspended",
    date: "Nov 24, 2023",
    type: "Agency",
  },
  {
    key: "8",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Suspended",
    date: "Nov 24, 2023",
    type: "Brand",
  },
  {
    key: "9",
    adImg: Eva,
    adName: "Eva bottling Company",
    reason: "The advisers was banned because of the following reasons.....",
    status: "Banned",
    date: "Nov 24, 2023",
    type: "Agency",
  },
];

export const approveActiveAdvertisers = [
  {
    key: "0",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Agency",
  },
  {
    key: "1",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Brand",
  },
  {
    key: "2",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Agency",
  },
  {
    key: "3",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Brand",
  },
  {
    key: "4",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Agency",
  },
  {
    key: "5",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Brand",
  },
  {
    key: "6",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Agency",
  },
  {
    key: "7",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Brand",
  },
  {
    key: "8",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Agency",
  },
  {
    key: "9",
    adImg: Eva,
    adName: "Eva bottling Company",
    phoneNumber: "+234 8081251512",
    email: "newuser@email.com",
    type: "Brand",
  },
];

export const shekelsData = [
  {
    key: "0",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Health",
    date: "Nov 24, 2023",
  },
  {
    key: "1",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Finance",
    date: "Nov 24, 2023",
  },
  {
    key: "2",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Music",
    date: "Nov 24, 2023",
  },
  {
    key: "3",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Health",
    date: "Nov 24, 2023",
  },
  {
    key: "4",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Finance",
    date: "Nov 24, 2023",
  },
  {
    key: "5",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Music",
    date: "Nov 24, 2023",
  },
  {
    key: "6",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Health",
    date: "Nov 24, 2023",
  },
  {
    key: "7",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Finance",
    date: "Nov 24, 2023",
  },
  {
    key: "8",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Music",
    date: "Nov 24, 2023",
  },
  {
    key: "9",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Music",
    date: "Nov 24, 2023",
  },
  {
    key: "10",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    shekelsEarned: 22,
    tribe: "Music",
    date: "Nov 24, 2023",
  },
];

export const transactionHistory = [
  {
    key: "0",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Earn",
    shekelsEarned: 22,
    isFlagged: false,
    date: "Nov 24, 2023",
    userType: "Watcher",
  },
  {
    key: "1",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Top-Up",
    shekelsEarned: 22,
    isFlagged: false,
    date: "Nov 24, 2023",
    userType: "Advertiser",
  },
  {
    key: "2",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Withdrawal",
    shekelsEarned: 22,
    isFlagged: true,
    date: "Nov 24, 2023",
    userType: "Watcher",
  },
  {
    key: "3",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Earn",
    shekelsEarned: 22,
    isFlagged: false,
    date: "Nov 24, 2023",
    userType: "Advertiser",
  },
  {
    key: "4",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Earn",
    shekelsEarned: 22,
    isFlagged: false,
    date: "Nov 24, 2023",
    userType: "Watcher",
  },
  {
    key: "5",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Top-Up",
    shekelsEarned: 22,
    isFlagged: false,
    date: "Nov 24, 2023",
    userType: "Advertiser",
  },
  {
    key: "6",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Withdrawal",
    shekelsEarned: 22,
    isFlagged: true,
    date: "Nov 24, 2023",
    userType: "Watcher",
  },
  {
    key: "7",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Earn",
    shekelsEarned: 22,
    isFlagged: false,
    date: "Nov 24, 2023",
    userType: "Advertiser",
  },
  {
    key: "8",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Top-Up",
    shekelsEarned: 22,
    isFlagged: false,
    date: "Nov 24, 2023",
    userType: "Watcher",
  },
  {
    key: "9",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Withdrawal",
    shekelsEarned: 22,
    isFlagged: true,
    date: "Nov 24, 2023",
    userType: "Advertiser",
  },
  {
    key: "10",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Withdrawal",
    shekelsEarned: 22,
    isFlagged: true,
    date: "Nov 24, 2023",
    userType: "Watcher",
  },
  {
    key: "11",
    userName: "Eva bottling Company",
    userImg: Eva,
    transactionType: "Withdrawal",
    shekelsEarned: 22,
    isFlagged: true,
    date: "Nov 24, 2023",
    userType: "Advertiser",
  },
];

export const revenueData = [
  {
    key: "0",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
  {
    key: "1",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
  {
    key: "2",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
  {
    key: "3",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
  {
    key: "4",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
  {
    key: "5",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
  {
    key: "6",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
  {
    key: "7",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
  {
    key: "8",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
  {
    key: "9",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertisersName: "Coca-cola",
    shekelsEarned: 22,
    revenue: 22,
    adPlacements: 22,
    date: "Nov 24, 2023",
  },
];

export const recentUsers = [
  {
    key: "0",
    phoneNumber: "080111125640",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    email: "email.example@email.com",
    userType: "Watchers",
    date: "Nov 24, 2023",
  },
  {
    key: "1",
    phoneNumber: "080111125640",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    email: "email.example@email.com",
    userType: "Advertisers",
    date: "Nov 24, 2023",
  },
  {
    key: "2",
    phoneNumber: "080111125640",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    email: "email.example@email.com",
    userType: "Watchers",
    date: "Nov 24, 2023",
  },
  {
    key: "3",
    phoneNumber: "080111125640",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    email: "email.example@email.com",
    userType: "Advertisers",
    date: "Nov 24, 2023",
  },
  {
    key: "4",
    phoneNumber: "080111125640",
    watchersName: "Adeola Adebola",
    watchersImg: Eva,
    email: "email.example@email.com",
    userType: "Advertisers",
    date: "Nov 24, 2023",
  },
];

export const viewersLocation = [
  {
    location: "Ikeja, Lagos",
    country: "NIG",
    percent: 17000,
  },
  {
    location: "Shagamu, Ogun",
    country: "NIG",
    percent: 5000,
  },
  {
    location: "Yaba, Lagos",
    country: "NIG",
    percent: 15000,
  },
  {
    location: "Shomolu, Lagos",
    country: "NIG",
    percent: 2000,
  },
  {
    location: "Surulere, Lagos",
    country: "NIG",
    percent: 15000,
  },
  {
    location: "Ikorodu, Lagos",
    country: "NIG",
    percent: 900,
  },
  {
    location: "Wuse II, Abuja",
    country: "NIG",
    percent: 9000,
  },
  {
    location: "Badagry, Lagos",
    country: "NIG",
    percent: 50,
  },
];

export const users = [
  {
    key: "0",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
  {
    key: "1",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
  {
    key: "2",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
  {
    key: "3",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
  {
    key: "4",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
  {
    key: "5",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
  {
    key: "6",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
  {
    key: "7",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
  {
    key: "8",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
  {
    key: "9",
    phoneNumber: "080111125640",
    userName: "Adeola Adebola",
    userImg: Eva,
    email: "email.example@email.com",
    date: "Nov 24, 2023",
    age: 24,
    location: "Ikeja, Lagos",
  },
];

export const adsWatched = [
  {
    key: "1",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Health",
    date: "Nov 24, 2023",
  },
  {
    key: "2",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Music",
    date: "Nov 24, 2023",
  },
  {
    key: "3",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Finance",
    date: "Nov 24, 2023",
  },
  {
    key: "4",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Health",
    date: "Nov 24, 2023",
  },
  {
    key: "5",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Music",
    date: "Nov 24, 2023",
  },
  {
    key: "6",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Finance",
    date: "Nov 24, 2023",
  },
  {
    key: "7",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Health",
    date: "Nov 24, 2023",
  },
  {
    key: "8",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Music",
    date: "Nov 24, 2023",
  },
  {
    key: "91",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Finance",
    date: "Nov 24, 2023",
  },
  {
    key: "20",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Health",
    date: "Nov 24, 2023",
  },
  {
    key: "11",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Music",
    date: "Nov 24, 2023",
  },
  {
    key: "12",
    videoImage: AdsGif,
    videoTitle: "Explore the Galaxy with Cosmic Adventures!",
    videoContent:
      "Embark on a cosmic journey with our cutting-edge space exploration gear. Unleash the adventurer in you and discover the wonders of the galaxy. From high-tech telescopes to astronaut-approved spacesuits, we've got everything you need for an out-of-this-world experience. Start your cosmic adventure today!",
    advertiser: "Coca-cola",
    earnings: 22,
    tribe: "Finance",
    date: "Nov 24, 2023",
  },
];
