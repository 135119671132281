export const required = (message: string) => ({
  required: true,
  message: message,
});

export const passwordRules = [
  { min: 8, message: "Password must have a minimum length of 8" },
  //   {
  //     pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{1,}$/,
  //     message:
  //       "At least one uppercase letter, one lowercase letter, one symbol and one number",
  //   },
];
