import { globalTheme } from "@/theme/theme";
import { ThemeConfig } from "antd";

export const advertisersManagementAntdTheme: ThemeConfig | undefined = {
  components: {
    Button: {
      colorPrimary: globalTheme.color.white,
      primaryColor: globalTheme.color.primary,
    },
  },
};
