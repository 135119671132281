import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const UserAnalyticsLayout = styled(StyledFlex)`
  width: 100%;
`;

export const UserAnalyticsFilterContainer = styled.div`
  width: 23%;
  width: 169px;
  & > * {
    width: 100%;
  }
`;

export const SearchContainer = styled.div`
  width: 338px;
`;

export const WatchersImg = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const WatchersAgeChart = styled.div`
  width: 57%;
  padding: 16px 32px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray200}`};
  background: ${({ theme }) => `${theme.color.white}`};
`;

export const ViewersGenderChart = styled(StyledFlex)`
  width: 33%;
  padding: 24px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray200}`};
  background: ${({ theme }) => `${theme.color.white}`};
`;

export const ViewersLocation = styled.div`
  padding: 16px 32px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray200}`};
  background: ${({ theme }) => `${theme.color.white}`};
`;

export const ProgressColumnContainer = styled(StyledFlex)`
  width: 48%;
`;
