import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { AdManagementLayout } from "./AdManagement.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { ConfigProvider, TabsProps } from "antd";
import { StyledTabs } from "@/components/Dashboard/Tabs/Tabs.styles";
import AdsManagementTable from "@/components/Dashboard/Analytics/AdsManagementTable/AdsManagementTable";
import { adManagementAntdTheme } from "./AdManagement.theme";

const AdManagement = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Active  Ad",
      children: <AdsManagementTable />,
    },
    {
      key: "2",
      label: "Banned  Ads",
      children: <AdsManagementTable />,
    },
  ];

  return (
    <ConfigProvider theme={adManagementAntdTheme}>
      <AdManagementLayout gap={32} vertical $alignSelf="stretch">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Ad Management
        </StyledTypography>
        <StyledDivider />
        <AdsManagementTable />
        {/* <StyledTabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
      </AdManagementLayout>
    </ConfigProvider>
  );
};

export default AdManagement;
