import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const AdvertisersManagementLayout = styled(StyledFlex)`
  width: 100%;
`;

export const SearchContainer = styled.div`
  width: 328px;
`;
