import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const ShekelsAnalyticsLayout = styled(StyledFlex)`
  width: 100%;
`;

export const ShekelsAnalyticsFilterContainerOne = styled.div`
  width: 18%;
  width: 169px;
  & > * {
    width: 100%;
  }
`;

export const ShekelsAnalyticsFilterContainerTwo = styled.div`
  width: 23%;
  width: 211px;
  & > * {
    width: 100%;
  }
`;

export const SearchContainer = styled.div`
  width: 338px;
`;

export const VideoContentData = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
`;

export const VideoContentDataText = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: start;
`;

export const VideoContentImg = styled.img`
  object-fit: cover;
  width: 88px;
  height: 73px;
`;

export const WatchersImg = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
