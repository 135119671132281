import { Table } from "antd";
import styled from "styled-components";
import { ITableProps } from "./Table.types";

export const StyledTable = styled(Table)<Pick<ITableProps, "isPaginated">>`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.color.gray200}`};
  border-bottom: ${({ isPaginated, theme }) =>
    isPaginated ? `1px solid ${theme.color.gray200}` : "none"};
  border-radius: 8px;
  .ant-table-cell::before {
    display: none;
  }
  .ant-table {
  }
  .ant-table-tbody > tr > td {
    padding: 16px 24px;
  }
  .ant-table-thead > tr > th {
    padding: 12px 24px;
  }
  .ant-table-pagination.ant-pagination {
    display: ${({ isPaginated }) => (isPaginated ? "flex" : "none")};
    margin: 11px 24px 16px auto;
    width: fit-content;
    box-shadow: ${({ theme }) => theme.shadow.shadowXs};
    border-radius: 33px;
    background-color: ${({ theme }) => theme.color.white};
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    border: ${({ theme }) => `1px solid ${theme.color.gray300}`};
    margin-inline-end: 0;
  }
  .ant-pagination-prev {
    border-radius: 33px 0px 0px 33px;
  }
  .ant-pagination-next {
    border-radius: 0px 33px 33px 0px;
    border-left: none;
  }
  .ant-pagination-item {
    border-radius: 0;
    margin-inline-end: 0;
    border: ${({ theme }) => `1px solid ${theme.color.gray300}`};
    border-left: none;
    &-active {
      &:hover {
        border-color: ${({ theme }) => theme.color.gray300};
      }
      &:hover a,
      a {
        color: ${({ theme }) => theme.color.gray800};
      }
    }
  }
`;
