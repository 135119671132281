import { FC } from "react";
import { ISelectProps } from "./Select.types";
import { StyledSelect } from "./Select.styles";
import { ArrowDown } from "@/assets/vectors";

const Select: FC<ISelectProps> = ({ ...rest }) => {
  return <StyledSelect {...rest} suffixIcon={<ArrowDown />} />;
};

export default Select;
