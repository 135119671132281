import { globalTheme } from "@/theme/theme";
import { ThemeConfig } from "antd";

export const authAntdTheme: ThemeConfig | undefined = {
  token: {
    borderRadius: 33,
  },
  components: {
    Button: {
      fontSize: 16,
    },
    Input: {
      fontSize: 16,
      colorBorder: globalTheme.color.black70,
      colorText: globalTheme.color.gray90,
    },
  },
};
