import { FC } from "react";
import { PieChartProps } from "./PieChart.types";
import ReactApexChart from "react-apexcharts";
import { globalTheme } from "@/theme/theme";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  StyledPieChartCategoryColor,
  StyledPieChartCategoryContainer,
} from "./PieChart.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";

const PieChart: FC<PieChartProps> = ({
  categories,
  width,
  height,
  ...rest
}) => {
  const pieAChartsOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "donut",
      width: "100%",
      dropShadow: {
        enabled: false,
      },
      fontFamily: globalTheme.font.fontFamily,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      colors: categories.map((item) => item.color),
    },
    stroke: {
      show: false,
    },
    labels: categories.map((item) => item.label),
    legend: {
      show: false,
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "white",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
          size: "45%",
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
  };

  return (
    <StyledFlex
      $alignSelf="stretch"
      vertical
      gap="24px"
      justify="center"
      align="center"
    >
      <ReactApexChart
        width={width ?? 160}
        height={height ?? 160}
        options={pieAChartsOptions}
        {...rest}
        type="donut"
      />
      <StyledPieChartCategoryContainer
        gap="13px"
        wrap="wrap"
        $alignSelf="stretch"
        justify="center"
      >
        {categories.map(({ color, label }) => (
          <StyledFlex gap="8px" align="center" key={label}>
            <StyledPieChartCategoryColor $bgColor={color} />
            <StyledTypography
              $variantColor="gray500"
              $variantSize="sm"
              $fontWeight={400}
            >
              {label}
            </StyledTypography>
          </StyledFlex>
        ))}
      </StyledPieChartCategoryContainer>
    </StyledFlex>
  );
};

export default PieChart;
