import { ConfigProvider, TabsProps } from "antd";
import { AdvertisersManagementLayout } from "./AdvertisersManagement.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { StyledTabs } from "@/components/Dashboard/Tabs/Tabs.styles";
import { advertisersManagementAntdTheme } from "./AdvertisersManagement.theme";
import ActiveAdvertisersTable from "@/components/Dashboard/Analytics/AdvertisersTable/ActiveAdvertisersTable";
import InActiveAdvertisersTable from "@/components/Dashboard/Analytics/AdvertisersTable/InActiveAdvertisersTable";
import ApproveAdvertisersTable from "@/components/Dashboard/Analytics/AdvertisersTable/ApproveAdvertisersTable";

const AdvertisersManagement = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Active Advertisers",
      children: <ActiveAdvertisersTable />,
    },
    {
      key: "2",
      label: "Inactive Advertisers",
      children: <InActiveAdvertisersTable />,
    },
    {
      key: "3",
      label: "Approve Advertisers (12)",
      children: <ApproveAdvertisersTable />,
    },
  ];

  return (
    <ConfigProvider theme={advertisersManagementAntdTheme}>
      <AdvertisersManagementLayout gap={32} vertical $alignSelf="stretch">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Advertisers Management
        </StyledTypography>
        <StyledDivider />
        <ActiveAdvertisersTable />
        {/* <StyledTabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
      </AdvertisersManagementLayout>
    </ConfigProvider>
  );
};

export default AdvertisersManagement;
