import {
  IPaginationMeta,
  IPaginationParams,
  ISODateString,
} from "@/types/global";
import API, { BaseResponse } from "./API";
import Endpoints from "./endpoints";
import { ITribe } from "@/types/tribe";

interface ICreateTribeRequest {
  name: string;
  description: string;
  uuid: string | null;
}

type IEditTribeRequest = Pick<ITribe, "name" | "description"> & { id: string };

export const getTribes = async (
  params: IPaginationParams & {
    from?: ISODateString;
    to?: ISODateString;
    status?: Pick<ITribe, "status">["status"];
  }
): Promise<BaseResponse<ITribe[], IPaginationMeta>> => {
  return API.get<ITribe[], IPaginationMeta>(Endpoints.ALL_TRIBES, {
    params,
  });
};

export const createTribe = async (
  payload: ICreateTribeRequest
): Promise<BaseResponse<any>> => {
  return API.post<ICreateTribeRequest, any>(Endpoints.ALL_TRIBES, payload);
};

export const editTribe = async ({
  id,
  ...body
}: IEditTribeRequest): Promise<BaseResponse<any>> => {
  return API.patch<Omit<IEditTribeRequest, "id">, any>(
    `${Endpoints.ALL_TRIBES}/${id}`,
    body
  );
};

export const deleteTribe = async ({
  id,
}: {
  id: string;
}): Promise<BaseResponse<any>> => {
  return API.delete<any>(`${Endpoints.ALL_TRIBES}/${id}`);
};
