import { FC } from "react";
import { StyledButtonContainer } from "./GoBack.styles";
import { useNavigate } from "react-router-dom";
import { ArrowLeftTwo } from "@/assets/vectors";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { IGoBackProps } from "./GoBack.types";

const GoBack: FC<IGoBackProps> = ({ onBackClick }) => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <StyledButtonContainer onClick={handleBackClick ?? onBackClick}>
      <ArrowLeftTwo />
      <StyledTypography
        $variantColor="black80"
        $variantSize="md"
        $fontWeight={500}
      >
        Go back
      </StyledTypography>
    </StyledButtonContainer>
  );
};

export default GoBack;
