import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  StyledComment,
  StyledCommentImage,
  StyledCommentsList,
} from "./AdComments.styles";
import { useParams } from "react-router-dom";
import { FC, useEffect, useMemo, useState } from "react";
import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  QUERY_KEY_ADVERTISEMENT,
  QUERY_KEY_AD_COMMENTS,
} from "@/constants/api";
import { deleteComment, getAdvertComments } from "@/network/advertisement";
import { formatISODateString } from "@/utils/helper-functions";
import { useInView } from "react-intersection-observer";
import { Spin } from "antd";
import AdCommentReplies from "./AdCommentReply";

const DeleteComment: FC<{
  adId?: string;
  commentId: number;
  queryClient: QueryClient;
}> = ({ commentId, adId, queryClient }) => {
  const {
    mutateAsync: deleteCommentMutation,
    isPending: isPendingDeleteComment,
  } = useMutation({
    mutationFn: deleteComment,
    onSuccess: () => {
      console.log("dd");
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_AD_COMMENTS, adId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISEMENT, adId],
      });
    },
  });

  return (
    <button
      disabled={isPendingDeleteComment}
      onClick={async () => {
        if (adId && commentId) {
          const res = await deleteCommentMutation({
            adId,
            commentId: `${commentId}`,
          });
          console.log(res);
        }
      }}
    >
      <StyledTypography
        onClick={() => {}}
        $variantColor="red"
        $variantSize="xs"
      >
        {isPendingDeleteComment ? "Deleting..." : "Delete"}
      </StyledTypography>
    </button>
  );
};

const AdComments = () => {
  const params = useParams();
  const adId = params?.id;
  const [lastElementRef, inView] = useInView();
  const [showReplies, setShowReplies] = useState<number[]>([]);

  // Access the client
  const queryClient = useQueryClient();

  const {
    data: comments,
    fetchNextPage: fetchNextPageComments,
    hasNextPage: hasNextPageComments,
    isFetching: isFetchingComments,
    isLoading: isLoadingComments,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY_AD_COMMENTS, adId],
    queryFn: ({ pageParam }) =>
      adId
        ? getAdvertComments(adId, {
            limit: 10,
            page: pageParam as number,
          })
        : undefined,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage &&
      "data" in lastPage &&
      lastPage.currentPage < lastPage.totalPages
        ? lastPage.currentPage + 1
        : null,
  });

  useEffect(() => {
    console.log("Inview", inView);
    if (inView && hasNextPageComments) {
      fetchNextPageComments();
    }
  }, [inView]);

  const flattenedComments = useMemo(
    () =>
      comments
        ? comments?.pages?.flatMap((page) =>
            page && "data" in page ? page.data : []
          )
        : [],
    [comments]
  );
  const totalNumberOfComments =
    comments?.pages?.length &&
    comments?.pages[0] &&
    "data" in comments?.pages[0]
      ? comments?.pages[0]?.totalDocuments
      : 0;

  return (
    <StyledFlex vertical $alignSelf="stretch" gap={24}>
      <StyledTypography $variantColor="black100" $variantSize="md">
        {totalNumberOfComments} Comment
        {totalNumberOfComments > 1 ? "s" : ""}
      </StyledTypography>
      <StyledCommentsList>
        {flattenedComments.map(
          ({
            comment,
            metadata: { totalReplies },
            user: {
              profile: { avatar, firstName, lastName, username },
            },
            createdAt,
            id,
          }) => (
            <StyledFlex
              key={id}
              vertical
              $alignSelf="stretch"
              align="flex-start"
              gap={14}
            >
              <StyledComment>
                <StyledCommentImage src={avatar?.publicURL ?? ""} alt="" />
                <StyledFlex vertical $alignSelf="stretch" gap={8}>
                  <StyledFlex $alignSelf="stretch" gap={4}>
                    <StyledTypography
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={600}
                    >
                      {firstName} {lastName}
                    </StyledTypography>
                    <StyledTypography $variantColor="black80" $variantSize="xs">
                      @{username}
                    </StyledTypography>
                  </StyledFlex>
                  <StyledTypography $variantColor="black100" $variantSize="sm">
                    {comment}
                  </StyledTypography>
                  <StyledFlex $alignSelf="stretch" gap={8}>
                    <StyledTypography $variantColor="black80" $variantSize="xs">
                      {formatISODateString(createdAt)}
                    </StyledTypography>
                    <DeleteComment
                      queryClient={queryClient}
                      adId={adId}
                      commentId={id}
                    />
                  </StyledFlex>
                </StyledFlex>
              </StyledComment>
              {showReplies.includes(id) ? (
                <AdCommentReplies commentId={`${id}`} adId={adId} />
              ) : null}
              {totalReplies ? (
                <button
                  onClick={() => {
                    setShowReplies((prev) =>
                      prev.includes(id)
                        ? prev.filter((item) => item !== id)
                        : [...prev, id]
                    );
                  }}
                >
                  <StyledTypography
                    $variantColor="primary"
                    $variantSize="sm"
                    $fontWeight={500}
                    $underline="underline"
                  >
                    {showReplies.includes(id) ? "Hide replies" : "Show replies"}
                  </StyledTypography>
                </button>
              ) : null}
            </StyledFlex>
          )
        )}
      </StyledCommentsList>
      <div ref={lastElementRef} />
      {isLoadingComments || isFetchingComments ? <Spin /> : null}
    </StyledFlex>
  );
};

export default AdComments;
