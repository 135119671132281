import { FC } from "react";
import ReactApexChart from "react-apexcharts";
import type { AreaChartProps } from "./AreaChart.types";
import type { ApexOptions } from "apexcharts";
import { globalTheme } from "@/theme/theme";

const AreaChart: FC<AreaChartProps> = (props) => {
  const areaChartOptions: ApexOptions = {
    stroke: {
      curve: "smooth",
      width: 2,
    },
    chart: {
      fontFamily: globalTheme.font.fontFamily,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [globalTheme.color.primary],
    fill: {
      opacity: 1,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    grid: {
      borderColor: globalTheme.color.black50,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      title: {
        text: "Days",
        style: {
          color: globalTheme.color.black90,
          fontSize: "12px",
        },
      },
      //   categories: [
      //     "2",
      //     "4",
      //     "6",
      //     "8",
      //     "10",
      //     "12",
      //     "14",
      //     "16",
      //     "18",
      //     "20",
      //     "22",
      //     "24",
      //     "26",
      //     "28",
      //     "30",
      //   ],
    },
    yaxis: {
      labels: {
        // formatter: (value) => `${value.toStr}`,
        style: {
          fontSize: "12px",
          colors: globalTheme.color.black100,
        },
      },
      title: {
        text: "Amount",
        style: {
          color: globalTheme.color.black90,
          fontSize: "12px",
        },
      },
    },
  };
  return <ReactApexChart options={areaChartOptions} {...props} type="area" />;
};

export default AreaChart;
