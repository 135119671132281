import React from "react";
import {
  StyledDropdown,
  StyledDropdownMenu,
  StyledDropdownMenuItem,
} from "./Dropdown.styles";
import { IDropdownProps } from "./Dropdown.types";

const Dropdown: React.FC<IDropdownProps> = ({ menu, ...rest }) => {
  return (
    <StyledDropdown
      menu={{ ...menu }}
      {...rest}
      dropdownRender={() => (
        <StyledDropdownMenu>
          {menu?.items?.map((item) => (
            <StyledDropdownMenuItem key={item?.key}>
              {item && "label" in item ? item?.label : null}
            </StyledDropdownMenuItem>
          ))}
        </StyledDropdownMenu>
      )}
    />
  );
};

export default Dropdown;
