import { FC } from "react";
import ReactApexChart from "react-apexcharts";
import type { BarChartProps } from "./BarChart.types";
import type { ApexOptions } from "apexcharts";
import { globalTheme } from "@/theme/theme";

const BarChart: FC<BarChartProps> = ({ categories, ...props }) => {
  const barChartOptions: ApexOptions = {
    chart: {
      type: "bar",
      fontFamily: globalTheme.font.fontFamily,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [globalTheme.color.primary],
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    grid: {
      borderColor: globalTheme.color.black50,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          fontSize: "12px",
          colors: globalTheme.color.black100,
        },
      },
    },
  };
  return <ReactApexChart options={barChartOptions} {...props} type="bar" />;
};

export default BarChart;
