import { FC } from "react";
import {
  StyledAuthCard,
  StyledAuthFooter,
  StyledAuthHeader,
  StyledAuthLayoutContainer,
  StyledFlexContainer,
} from "./AuthLayout.styles";
import { Logo } from "@/assets/vectors";
import { StyledContainer } from "../Container/Container.styles";
import { authAntdTheme } from "./AuthLayout.theme";
import { ConfigProvider } from "antd";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { IAuthLayoutProps } from "./AuthLayout.types";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { Link } from "react-router-dom";

const AuthLayout: FC<IAuthLayoutProps> = ({ children }) => {
  return (
    <ConfigProvider theme={authAntdTheme}>
      <StyledContainer>
        <StyledAuthLayoutContainer>
          <StyledFlexContainer vertical>
            <StyledAuthHeader>
              <Link to="/">
                <Logo />
              </Link>
            </StyledAuthHeader>
            <StyledFlex justify="center" align="center" flex={1}>
              <StyledAuthCard
                vertical
                justify="center"
                align="flex-start"
                component={"article"}
              >
                {children}
              </StyledAuthCard>
            </StyledFlex>
            <StyledAuthFooter>
              <StyledTypography $variantColor="white" $variantSize="sm">
                © Lohli 2023
              </StyledTypography>
            </StyledAuthFooter>
          </StyledFlexContainer>
        </StyledAuthLayoutContainer>
      </StyledContainer>
    </ConfigProvider>
  );
};

export default AuthLayout;
