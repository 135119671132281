import { Lock } from "@/assets/vectors";
import { StyledButton } from "@/components/Auth/Button/Button.style";
import { StyledForm } from "@/components/Auth/Form/Form.styles";
import FormHeader from "@/components/Auth/FormHeader/FormHeader";
import { StyledInput } from "@/components/Auth/Input/Input.style";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import AuthLayout from "@/layouts/AuthLayout/AuthLayout";
import { ResetPasswordFieldType } from "./ResetPassword.types";
import { Form, message } from "antd";
import { passwordRules, required } from "@/utils/validation";
import { FC } from "react";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "@/network/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TOKEN_NAME } from "@/constants/api";

const ResetPassword: FC = () => {
  const { mutate, data, isPending } = useMutation({
    mutationFn: resetPassword,
  });

  const [params] = useSearchParams();
  const navigate = useNavigate();

  const onFinish = ({ confirmPassword }: ResetPasswordFieldType) => {
    const email = params.get("email");
    const token = params.get("token");
    if (typeof email == "string" && typeof token === "string") {
      mutate({
        email,
        password: confirmPassword,
        token,
      });
    }
  };

  if (data) {
    if ("statusCode" in data) {
      message.error(data.message);
    } else {
      console.log(data);
      message.success(data.message ?? "Updated password successfully");
      sessionStorage.removeItem(TOKEN_NAME);
      navigate("/signin");
    }
  }

  return (
    <AuthLayout>
      <StyledForm name="reset-password" onFinish={onFinish}>
        <StyledFlex vertical align="flex-start" gap={32} $alignSelf="stretch">
          <FormHeader
            heading="Reset Password"
            subHeading="Fill in your details"
          />
          <StyledFlex gap={20} vertical align="stretch" $alignSelf="stretch">
            <Form.Item<ResetPasswordFieldType>
              name="password"
              rules={[required("Please input your Password"), ...passwordRules]}
            >
              <StyledInput
                prefix={<Lock />}
                placeholder="New Password"
                type="password"
              />
            </Form.Item>
            <Form.Item<ResetPasswordFieldType>
              name="confirmPassword"
              rules={[
                required("Please input your Password"),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <StyledInput
                prefix={<Lock />}
                placeholder="Confirm New Password"
                type="password"
              />
            </Form.Item>
          </StyledFlex>
        </StyledFlex>
        <StyledButton
          disabled={isPending}
          loading={isPending}
          type="primary"
          htmlType="submit"
        >
          Continue
        </StyledButton>
      </StyledForm>
    </AuthLayout>
  );
};

export default ResetPassword;
