import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const TopPerformingTribesTable = styled.div`
  width: 64%;
`;

export const TopPerformingTribesChart = styled.div`
  width: 32%;
  padding: 24px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray200}`};
  background: ${({ theme }) => `${theme.color.white}`};
`;

export const PerformingTribesTableData = styled(StyledFlex)`
  align-items: center;
  height: 63px;
`;
