import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  AdminManagementLayout,
  SearchContainer,
  StyledActionBtn,
  StyledAddAdminActionButtonContainer,
  StyledTableImg,
} from "./AdminManagement.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledCard } from "@/components/Dashboard/Card/Card.styles";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import { Delete, Edit, Plus, Search } from "@/assets/vectors";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";
import type { ColumnsType } from "antd/es/table";
import AvartarImg from "@/assets/images/avartar.png";
import { StyledPill } from "@/components/Dashboard/Pill/Pill.styles";
import Table from "@/components/Dashboard/Table/Table";
import { useState } from "react";
import Modal from "@/components/Dashboard/Modal/Modal";
import Select from "@/components/Dashboard/Select/Select";
import { AddAdminFieldType } from "./AdminManagement.types";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { required } from "@/utils/validation";
import { useMutation } from "@tanstack/react-query";
import { createAdmin } from "@/network/auth";
import { Form, message } from "antd";
interface DataType {
  key: string;
  name: string;
  email: string;
  image: string;
  role: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Admin Full Name",
    key: "name",
    render: (_, { name }) => (
      <StyledFlex gap={12} align="center">
        <StyledTableImg src={AvartarImg} alt="" />
        <StyledTypography $variantColor="gray90" $variantSize="sm">
          {name}
        </StyledTypography>
      </StyledFlex>
    ),
    width: "36%",
  },
  {
    title: "Email",
    key: "email",
    render: () => (
      <StyledTypography $variantColor="gray500" $variantSize="sm">
        example.email@lohli.com
      </StyledTypography>
    ),
    width: "30%",
  },
  {
    title: "Role",
    key: "role",
    render: () => (
      <StyledPill $bgColor="success50" $color="success700">
        Role 1
      </StyledPill>
    ),
    width: "16%",
  },
  {
    title: "",
    key: "key",
    render: () => (
      <StyledFlex gap={4} align="center">
        <StyledActionBtn>
          <Delete />
        </StyledActionBtn>
        <StyledActionBtn>
          <Edit />
        </StyledActionBtn>
      </StyledFlex>
    ),
    width: "8%",
  },
];

const AdminManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm<AddAdminFieldType>();

  const { mutate, data, isPending } = useMutation({
    mutationFn: createAdmin,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values: AddAdminFieldType) => {
    console.log("Success:", values);
    mutate({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      redirectUrl: "https://lohli-admin-frontend.vercel.app/verify-signup",
    });
  };

  if (data) {
    if ("statusCode" in data) {
      message.error(data.message);
    } else {
      message.success(data.message);
    }
  }

  return (
    <AdminManagementLayout gap={32} vertical $alignSelf="stretch">
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        heading="Add an Admin User"
        footer={
          <StyledAddAdminActionButtonContainer justify="flex-end">
            <StyledButton
              loading={isPending}
              disabled={isPending}
              form="add-admin"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              Create
            </StyledButton>
            <StyledButton onClick={hideModal} type="default">
              Cancel
            </StyledButton>
          </StyledAddAdminActionButtonContainer>
        }
      >
        <StyledForm
          form={form}
          layout="vertical"
          name="add-admin"
          onFinish={onFinish}
        >
          <StyledFlex gap={16} vertical $alignSelf="stretch">
            <StyledForm.Item<AddAdminFieldType>
              name="firstName"
              rules={[required("Please input the first name!")]}
              label="First Name"
            >
              <StyledInput type="text" placeholder="Enter First Name" />
            </StyledForm.Item>
            <StyledForm.Item<AddAdminFieldType>
              name="lastName"
              rules={[required("Please input the last name!")]}
              label="Last Name"
            >
              <StyledInput type="text" placeholder="Enter Last Name" />
            </StyledForm.Item>
            <StyledForm.Item<AddAdminFieldType>
              name="email"
              rules={[
                required("Please input your Email!"),
                {
                  type: "email",
                  message: "Please input a valid Email",
                },
              ]}
              label="Email Address"
            >
              <StyledInput type="text" placeholder="Enter Email" />
            </StyledForm.Item>
            <StyledForm.Item<AddAdminFieldType>
              name="phoneNumber"
              rules={[required("Please input your Phone number!")]}
              label="Phone Number"
            >
              <StyledInput
                type="text"
                placeholder="Enter Phone number (+234XXXXXXXXXX)"
              />
            </StyledForm.Item>
            <StyledForm.Item<AddAdminFieldType>
              name="role"
              rules={[required("Please input your role")]}
              label="Role"
            >
              <Select
                placeholder="Select a Role type"
                options={[
                  { value: "role1", label: "Role 1" },
                  { value: "role2", label: "Role 2" },
                  { value: "role3", label: "Role 3" },
                ]}
              />
            </StyledForm.Item>
          </StyledFlex>
        </StyledForm>
      </Modal>
      <StyledFlex vertical gap={4} align="flex-start">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Admin Management
        </StyledTypography>
        <StyledTypography $variantColor="gray500" $variantSize="md">
          Add and manage other admin users.
        </StyledTypography>
      </StyledFlex>
      <StyledFlex gap={24} $alignSelf="stretch" align="center">
        {[
          {
            title: "Total Admin Users",
            value: "27",
          },
          {
            title: "Total Role 1",
            value: "20",
          },
          {
            title: "Total Role 2",
            value: "7",
          },
        ].map((item) => (
          <StyledFlex
            key={item.title}
            flex={"1 1 0"}
            $alignSelf="stretch"
            align="center"
          >
            <StyledCard>
              <StyledFlex gap={8} $alignSelf="stretch" vertical>
                <StyledTypography
                  $variantColor="black90"
                  $variantSize="sm"
                  $fontWeight={500}
                >
                  {item.title}
                </StyledTypography>
                <StyledTypography
                  $variantColor="black100"
                  $variantSize="3xl"
                  $fontWeight={500}
                >
                  {item.value}
                </StyledTypography>
              </StyledFlex>
            </StyledCard>
          </StyledFlex>
        ))}
      </StyledFlex>
      <StyledFlex gap={16} $alignSelf="stretch" justify="space-between">
        <SearchContainer>
          <StyledInput prefix={<Search />} placeholder="Search" />
        </SearchContainer>
        <StyledButton onClick={() => showModal()} type="primary">
          <Plus />
          <span>Add Admin Users</span>
        </StyledButton>
      </StyledFlex>
      <Table
        columns={columns}
        pageSize={10}
        isPaginated
        dataSource={[
          {
            key: "0",
            name: "Nicholas James",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "1",
            name: "Omolara Fredrick",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "2",
            name: "Adekunle Onobanjo",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "3",
            name: "Nicholas James",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "4",
            name: "Omolara Fredrick",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "5",
            name: "Adekunle Onobanjo",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
          {
            key: "6",
            name: "Nicholas James",
            email: "example.email@lohli.com",
            image: "string",
            role: "string",
          },
        ]}
      />
    </AdminManagementLayout>
  );
};

export default AdminManagement;
