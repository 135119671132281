import { Tabs } from "antd";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav::before {
    border-bottom: none;
  }
  & .ant-tabs-nav {
    margin-bottom: 32px;
  }
  & .ant-tabs-tab {
    height: 44px;
    padding: 8px 12px;
  }
  & .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  & > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 44px;
    background-color: ${({ theme }) => theme.color.brown500};
    z-index: -1;
  }
`;
