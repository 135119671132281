import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const WatchersManagementLayout = styled(StyledFlex)`
  width: 100%;
`;

export const SearchContainer = styled.div`
  width: 328px;
`;

export const UserImg = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const UserProfile = styled.img`
  object-fit: cover;
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

export const ProfileMetrics = styled(StyledFlex)`
  padding: 15px 48px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.black50}`};
  background: ${({ theme }) => `${theme.color.black50}`};
`;

export const VideoContentData = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
`;

export const VideoContentDataText = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: start;
`;

export const VideoContentImg = styled.img`
  object-fit: cover;
  width: 88px;
  height: 73px;
`;

export const WatchersBalance = styled(StyledFlex)`
  border-radius: 8px;
  background-color: ${({ theme }) => `${theme.color.primary}`};
  padding: 15px 48px;
`;
