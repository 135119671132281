import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const RevenueTrackingLayout = styled(StyledFlex)`
  width: 100%;
`;

export const RevenueTrackingFilterContainerOne = styled.div`
  /* max-width: 18%; */
  width: 169px;
  & > * {
    width: 100%;
  }
`;

export const SearchContainer = styled.div`
  width: 24%;
  max-width: 338px;
`;

export const VideoContentData = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
`;

export const VideoContentDataText = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: start;
`;

export const VideoContentImg = styled.img`
  object-fit: cover;
  width: 88px;
  height: 73px;
`;
