import type { ThemeConfig } from "antd";
import color from "./color";
import font from "./font";
import shadow from "./shadow";

export const globalTheme = {
  color,
  font,
  shadow,
} as const;

export const antdTheme: ThemeConfig | undefined = {
  token: {
    colorPrimary: globalTheme.color.primary,
    fontFamily: globalTheme.font.fontFamily,
  },
};
