import { globalTheme } from "@/theme/theme";
import { ThemeConfig } from "antd";

export const dashboardAntdTheme: ThemeConfig | undefined = {
  token: {
    borderRadius: 8,
    colorBorder: globalTheme.color.gray200,
  },
  components: {
    Button: {
      fontSize: 16,
      borderRadius: 33,
    },
    Input: {
      fontSize: 14,
      colorBorder: globalTheme.color.black70,
      colorText: globalTheme.color.gray90,
      borderRadius: 33,
      colorTextPlaceholder: globalTheme.color.black80,
    },
    DatePicker: {
      fontSize: 14,
      colorBorder: globalTheme.color.black60,
      colorText: globalTheme.color.black100,
      borderRadius: 8,
      colorTextPlaceholder: globalTheme.color.black100,
    },
    Select: {
      fontSize: 14,
      colorBorder: globalTheme.color.black70,
      colorText: globalTheme.color.gray90,
      borderRadius: 33,
      optionSelectedColor: globalTheme.color.white,
      optionSelectedBg: globalTheme.color.primary,
    },
    Table: {
      headerBg: globalTheme.color.black50,
      borderColor: globalTheme.color.gray200,
      headerColor: globalTheme.color.black100,
      fontSize: 14,
      fontWeightStrong: 500,
      headerBorderRadius: 8,
    },
    Pagination: {
      itemBg: globalTheme.color.white,
      itemActiveBg: globalTheme.color.gray50,
      itemLinkBg: globalTheme.color.gray300,
      itemSize: 40,
      fontSize: 14,
    },
    Modal: {
      borderRadius: 12,
      titleFontSize: 20,
      titleColor: globalTheme.color.black100,
    },
    Form: {
      labelFontSize: 14,
      labelColor: globalTheme.color.black100,
    },
    Tabs: {
      cardBg: globalTheme.color.brown500,
      cardHeight: 44,
      cardPadding: "8px 12px",
      titleFontSize: 16,
      horizontalMargin: "0",
      itemColor: globalTheme.color.gray300,
      itemActiveColor: globalTheme.color.grey500,
      itemHoverColor: globalTheme.color.grey500,
      itemSelectedColor: globalTheme.color.grey500,
    },
  },
};
