export const TOKEN_NAME = "LOHLI_TOKEN" as const;
export const REFRESH_TOKEN_NAME = "LOHLI_REFRESH_TOKEN" as const;
export const USER_DETAILS_LOCAL = "USER_DETAILS_LOCAL" as const;
export const QUERY_KEY_AD_PERFORMING_METRICS = "ALL_TIME_METRICS" as const;
export const QUERY_KEY_SHEKEL_METRICS = "SHEKEL_METRICS" as const;
export const QUERY_KEY_REVENUE_BAR_CHART = "REVENUE_BAR_CHART" as const;
export const QUERY_KEY_TRIBE_PIE_CHART = "TRIBE_REVENUE_PIE_CHART" as const;
export const QUERY_KEY_TOP_PERFORMING_ADS = "TOP_PERFORMING_ADS" as const;
export const QUERY_KEY_TOP_PERFORMING_TRIBES = "TOP_PERFORMING_TRIBES" as const;
export const QUERY_KEY_TOP_WATCHERS = "TOP_WATCHERS" as const;
export const QUERY_KEY_TOP_REVENUE_ADS = "TOP_REVENUE_ADS" as const;
export const QUERY_KEY_METRICS = "METRICS" as const;
export const QUERY_KEY_ADVERTISERS = "ADVERTISERS" as const;
export const QUERY_KEY_TRIBES = "TRIBES" as const;
export const QUERY_KEY_TOP_VIEWERS_LOCATION = "TOP_VIEWERS_LOCATION" as const;
export const QUERY_KEY_TOP_WATCHERS_GENDER = "TOP_WATCHERS_GENDER" as const;
export const QUERY_KEY_TOP_WATCHERS_AGE = "TOP_WATCHERS_AGE" as const;
export const QUERY_KEY_RECENT_USERS = "RECENT_USERS" as const;
export const QUERY_KEY_WATCHERS = "WATCHERS" as const;
export const QUERY_KEY_WATCHER = "WATCHER" as const;
export const QUERY_KEY_WATCHER_WALLET = "WATCHER_WALLET" as const;
export const QUERY_KEY_ADS_WATCHED = "ADS_WATCHED" as const;
export const QUERY_KEY_ADS_WATCHER_STATS = "ADS_WATCHER_STATS" as const;
export const QUERY_KEY_ADVERTISEMENTS = "ADVERTISEMENTS" as const;
export const QUERY_KEY_ADVERTISEMENT = "ADVERTISEMENT" as const;
export const QUERY_KEY_AD_COMMENTS = "AD_COMMENTS" as const;
export const QUERY_KEY_AD_COMMENT_REPLIES = "AD_COMMENT_REPLIES" as const;
export const PAGINATION_LIMIT = 10 as const;
