import styled from "styled-components";

export const FilterContainerOne = styled.div`
  width: 18%;
  width: 169px;
  & > * {
    width: 100%;
  }
`;

export const FilterContainerTwo = styled.div`
  width: 23%;
  width: 211px;
  & > * {
    width: 100%;
  }
`;
