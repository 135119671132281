import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  SearchContainer,
  TransactionHistoryFilterContainerOne,
  TransactionHistoryFilterContainerTwo,
  TransactionHistoryLayout,
  UserImg,
} from "./TransactionHistory.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import Select from "@/components/Dashboard/Select/Select";
import { Download, Flag, Search } from "@/assets/vectors";
import { ConfigProvider, Form } from "antd";
import { transactionHistoryAntdTheme } from "./TransactionHistory.theme";
import RangePicker from "@/components/Dashboard/RangePicker/RangePicker";
import { DateTime } from "luxon";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import {
  StyledButton,
  StyledExportButton,
} from "@/components/Dashboard/Button/Button.styles";
import {
  StyledInput,
  StyledTextArea,
} from "@/components/Dashboard/Input/Input.styles";
import type { ColumnsType } from "antd/es/table";
import Table from "@/components/Dashboard/Table/Table";
import { transactionHistory } from "@/utils/mock-data";
import { StyledPill } from "@/components/Dashboard/Pill/Pill.styles";
import { useState } from "react";
import Modal from "@/components/Dashboard/Modal/Modal";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { FlagTransctionFieldType } from "./TransactionHistory.types";
import { required } from "@/utils/validation";
import { globalTheme } from "@/theme/theme";

interface DataType {
  key: string;
  userImg: string;
  userName: string;
  transactionType: "Earn" | "Top-Up" | "Withdrawal";
  shekelsEarned: string;
  date: string;
  userType: string;
  isFlagged: boolean;
}

const TransactionHistory = () => {
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);

  const [form] = Form.useForm<FlagTransctionFieldType>();
  const showTransactionModal = () => {
    setIsTransactionModalOpen(true);
  };

  const hideTransactionModal = () => {
    setIsTransactionModalOpen(false);
  };

  const onFinish = (values: FlagTransctionFieldType) => {
    console.log("Success:", values);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "User Name",
      key: "userName",
      render: (_, { userImg, userName }) => (
        <StyledFlex gap="12px" align="center">
          <UserImg src={userImg} alt="" />
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {userName}
          </StyledTypography>
        </StyledFlex>
      ),
      width: "39%",
    },
    {
      title: "User Type",
      key: "userType",
      render: (_, { userType }) => (
        <StyledTypography
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {userType}
        </StyledTypography>
      ),
      width: "11%",
    },
    {
      title: "Transaction Type",
      key: "transactionType",
      render: (_, { transactionType }) => (
        <StyledPill
          $bgColor={
            transactionType === "Earn"
              ? "success50"
              : transactionType === "Top-Up"
              ? "gray100"
              : "error50"
          }
          $color={
            transactionType === "Earn"
              ? "success700"
              : transactionType === "Top-Up"
              ? "gray700"
              : "error700"
          }
        >
          {transactionType}
        </StyledPill>
      ),
      width: "15%",
    },
    {
      title: "Amount",
      key: "shekelsEarned",
      render: (_, { shekelsEarned }) => (
        <StyledFlex vertical gap={1}>
          <StyledTypography
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {shekelsEarned} Shekels
          </StyledTypography>
          <StyledTypography
            $variantColor="gray500"
            $variantSize="xs"
            $fontWeight={400}
          >
            ₦ 4,400 in Naira
          </StyledTypography>
        </StyledFlex>
      ),
      width: "13%",
    },
    {
      title: "Transaction Date ",
      key: "date",
      render: (_, { date }) => (
        <StyledTypography
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {date}
        </StyledTypography>
      ),
      width: "15%",
    },
    {
      title: "",
      key: "key",
      render: (_, { isFlagged }) =>
        isFlagged ? (
          <StyledTypography
            $variantColor="primary"
            $variantSize="sm"
            $fontWeight={400}
          >
            Flagged
          </StyledTypography>
        ) : (
          <button onClick={showTransactionModal}>
            <Flag />
          </button>
        ),
      width: "7%",
    },
  ];

  return (
    <ConfigProvider theme={transactionHistoryAntdTheme}>
      {/* modal starts */}
      <ConfigProvider
        theme={{
          components: {
            Button: {
              primaryColor: globalTheme.color.white,
              colorPrimary: globalTheme.color.primary,
            },
          },
        }}
      >
        <Modal
          isModalOpen={isTransactionModalOpen}
          setIsModalOpen={setIsTransactionModalOpen}
          heading="Are you sure you want to flag this transaction?"
          footer={
            <StyledFlex justify="flex-end">
              <StyledButton onClick={hideTransactionModal} type="default">
                Cancel
              </StyledButton>
              <StyledButton
                form="ban-adds"
                key="submit"
                htmlType="submit"
                type="primary"
              >
                Yes, Flag Transaction
              </StyledButton>
            </StyledFlex>
          }
        >
          <StyledForm
            form={form}
            layout="vertical"
            name="add-admin"
            onFinish={onFinish}
          >
            <StyledFlex gap={38} vertical $alignSelf="stretch">
              <StyledTypography
                as="p"
                $variantColor="black100"
                $variantSize="md"
              >
                Flagging this transaction for further investigation will mark it
                for additional evaluation.
              </StyledTypography>
              <StyledForm.Item<FlagTransctionFieldType>
                name="reason"
                rules={[required("Please input your reason!")]}
                label="Give a Reason"
              >
                <StyledTextArea placeholder="Please provide a brief reason for flagging this transaction" />
              </StyledForm.Item>
            </StyledFlex>
          </StyledForm>
        </Modal>
      </ConfigProvider>
      {/* modal ends */}
      <TransactionHistoryLayout gap={32} vertical $alignSelf="stretch">
        <StyledFlex gap={4} vertical $alignSelf="stretch">
          <StyledTypography
            $variantColor="gray90"
            $variantSize="2xl"
            $fontWeight={500}
          >
            Transaction History
          </StyledTypography>
          <StyledTypography $variantColor="black80" $variantSize="sm">
            Payment Processing and Transaction History
          </StyledTypography>
        </StyledFlex>
        <StyledDivider />
        <StyledFlex gap={32} align="center" justify="space-between">
          <StyledFlex gap={16} flex="1" align="center">
            <TransactionHistoryFilterContainerOne>
              <RangePicker
                ranges={{
                  Today: [
                    DateTime.now().startOf("day"),
                    DateTime.now().endOf("day"),
                  ],
                  "This Week": [
                    DateTime.now().startOf("week").startOf("day"),
                    DateTime.now().endOf("week").endOf("day"),
                  ],
                  "This Month": [
                    DateTime.now().startOf("month").startOf("day"),
                    DateTime.now().endOf("month").endOf("day"),
                  ],
                  "This Year": [
                    DateTime.now().startOf("year").startOf("day"),
                    DateTime.now().endOf("year").endOf("day"),
                  ],
                }}
              />
            </TransactionHistoryFilterContainerOne>
            <TransactionHistoryFilterContainerOne>
              <Select placeholder="Filter Tribe" options={[]} />
            </TransactionHistoryFilterContainerOne>
            <TransactionHistoryFilterContainerTwo>
              <Select placeholder="Filter Advertisers" options={[]} />
            </TransactionHistoryFilterContainerTwo>
          </StyledFlex>
          <SearchContainer>
            <StyledInput prefix={<Search />} placeholder="Search" />
          </SearchContainer>
          <StyledExportButton type="primary">
            <Download />
            <span>Export</span>
          </StyledExportButton>
        </StyledFlex>
        <StyledFlex vertical gap={16}>
          <StyledTypography
            $variantColor="gray90"
            $variantSize="lg"
            $fontWeight={500}
          >
            Transaction data
          </StyledTypography>
          <Table
            columns={columns}
            dataSource={transactionHistory}
            isPaginated={true}
          />
        </StyledFlex>
      </TransactionHistoryLayout>
    </ConfigProvider>
  );
};

export default TransactionHistory;
