import { FC, useEffect } from "react";
import {
  StyledAdminAvartar,
  StyledAdminName,
  StyledAdminProfile,
  StyledAdminProfileContainer,
  StyledAdminType,
  StyledLogo,
  StyledMenuDropdownLinkList,
  StyledMenuLinkItem,
  StyledMenuLinkList,
  StyledMenuLinkName,
  StyledSidebar,
} from "./DashboardLayout.styles";
import { ISidebarProps } from "./DashboardLayout.types";
import {
  CoinStack,
  Dashboard,
  Film,
  Logo,
  Logout,
  Monitor,
  Radio,
  Settings,
  User,
} from "@/assets/vectors";
import { useLocation } from "react-router-dom";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import AvartarImg from "@/assets/images/avartar.png";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { TOKEN_NAME, USER_DETAILS_LOCAL } from "@/constants/api";
import { DASHBOARD_ROUTES } from "@/routes";
import { IUser } from "@/types/user";

const Sidebar: FC<ISidebarProps> = ({ setIsLoaded }) => {
  const location = useLocation();
  const user: IUser = JSON.parse(
    sessionStorage.getItem(USER_DETAILS_LOCAL) || "{}"
  );
  console.log(user);

  const renderSidebarIcon = (item: (typeof DASHBOARD_ROUTES)[number]) => {
    return item.name === "Dashboard" ? (
      <Dashboard />
    ) : item.name === "Watchers Management" ? (
      <Monitor />
    ) : item.name === "Advertisers Management" ? (
      <Radio />
    ) : item.name === "Admin Management" ? (
      <User />
    ) : item.name === "Tribe Management" ? (
      <CoinStack />
    ) : item.name === "Ad Management" ? (
      <Film />
    ) : null;
  };

  const handleLogout = () => {
    sessionStorage.removeItem(TOKEN_NAME);
    sessionStorage.removeItem(USER_DETAILS_LOCAL);
    window.location.replace("/");
  };

  useEffect(() => {
    setIsLoaded(true);
  });

  const pathSegments = location.pathname.split("/");
  const lastPath = pathSegments[pathSegments.length - 1];

  return (
    <StyledSidebar width={280}>
      <StyledLogo>
        <Logo />
      </StyledLogo>
      <StyledFlex
        vertical
        justify="space-between"
        flex="1 0 0"
        $alignSelf="stretch"
        align="center"
      >
        <StyledMenuLinkList gap={4} $alignSelf="stretch" vertical>
          {DASHBOARD_ROUTES.map((item) => (
            <StyledFlex vertical gap={4} key={item.name}>
              <StyledMenuLinkItem
                className={
                  item.children?.some((item) => lastPath === item.link)
                    ? "active"
                    : ""
                }
                to={item.link}
              >
                {renderSidebarIcon(item)}
                <StyledMenuLinkName>{item.name}</StyledMenuLinkName>
              </StyledMenuLinkItem>
              {/* checks for nested link and checks if current url matches item link */}
              {item?.children
                ?.filter((childrenItem) => "name" in childrenItem)
                ?.some((childrenItem) => lastPath === childrenItem.link) ||
              lastPath === item.link
                ? item?.children?.map((childrenItem) =>
                    "name" in childrenItem ? (
                      <StyledMenuDropdownLinkList
                        className={({ isActive, isPending }) =>
                          isActive ? "active" : isPending ? "pending" : ""
                        }
                        to={`${childrenItem.link}`}
                        key={childrenItem.name}
                      >
                        {childrenItem.name}
                      </StyledMenuDropdownLinkList>
                    ) : null
                  )
                : null}
            </StyledFlex>
          ))}
        </StyledMenuLinkList>
        <StyledMenuLinkList $alignSelf="stretch" gap={30} vertical>
          <StyledMenuLinkItem
            className={lastPath === "/dashboard/settings" ? "active" : ""}
            to={"/dashboard/settings"}
          >
            <Settings />
            <StyledMenuLinkName>Settings</StyledMenuLinkName>
          </StyledMenuLinkItem>
          <StyledAdminProfileContainer $alignSelf="stretch" gap={30} vertical>
            <button onClick={handleLogout}>
              <StyledFlex gap={8} align="center">
                <Logout />
                <StyledTypography $variantColor="grey600" $variantSize="md">
                  Log Out
                </StyledTypography>
              </StyledFlex>
            </button>
            <StyledAdminProfile gap={8} align="center">
              <StyledAdminAvartar src={AvartarImg} />
              <StyledFlex vertical justify="center" align="flex-start" gap={4}>
                <StyledAdminName $variantColor="white" $variantSize="md" as="p">
                  {user?.profile?.firstName} {user?.profile?.lastName}
                </StyledAdminName>
                <StyledAdminType $variantColor="white" $variantSize="xs" as="p">
                  Super Admin
                </StyledAdminType>
              </StyledFlex>
            </StyledAdminProfile>
          </StyledAdminProfileContainer>
        </StyledMenuLinkList>
      </StyledFlex>
    </StyledSidebar>
  );
};

export default Sidebar;
