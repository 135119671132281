import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const TransactionHistoryLayout = styled(StyledFlex)`
  width: 100%;
`;

export const TransactionHistoryFilterContainerOne = styled.div`
  width: 18%;
  width: 169px;
  & > * {
    width: 100%;
  }
`;

export const TransactionHistoryFilterContainerTwo = styled.div`
  width: 23%;
  width: 211px;
  & > * {
    width: 100%;
  }
`;

export const SearchContainer = styled.div`
  width: 24%;
  max-width: 338px;
`;

export const UserImg = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
