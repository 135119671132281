import AdminManagement from "@/routes/Dashboard/AdminManagement/AdminManagement";
import AdvertisersManagement from "@/routes/Dashboard/AdvertisersManagement/AdvertisersManagement";
import AdManagement from "@/routes/Dashboard/Analytics/AdManagement/AdManagement";
import AdPerformance from "@/routes/Dashboard/Analytics/AdPerformance/AdPerformance";
import AdRevenue from "@/routes/Dashboard/Analytics/AdRevenue/AdRevenue";
import Home from "@/routes/Dashboard/Analytics/Home/Home";
import SingleAd from "@/routes/Dashboard/Analytics/SingleAd/SingleAd";
import ManageConversionRate from "@/routes/Dashboard/Shekels/ManageConversionRate/ManageConversionRate";
import RevenueTracking from "@/routes/Dashboard/Shekels/RevenueTracking/RevenueTracking";
import ShekelsAnalytics from "@/routes/Dashboard/Shekels/ShekelsAnalytics/ShekelsAnalytics";
import TransactionHistory from "@/routes/Dashboard/Shekels/TransactionHistory/TransactionHistory";
import UserAnalytics from "@/routes/Dashboard/UserAnalytics/UserAnalytics";
import WatchersManagement from "@/routes/Dashboard/WatchersManagement/WatchersManagement";
import TribeManagement from "@/routes/Dashboard/TribeManagement/TribeManagement";

interface Route {
  name: string;
  link: string;
  component: JSX.Element;
  children?: ChildItem[];
}

interface ChildItem {
  name?: string;
  link: string;
  component: JSX.Element;
}

export const DASHBOARD_ROUTES: Route[] = [
  {
    name: "Dashboard",
    link: "home",
    component: <Home />,
    children: [
      {
        name: "Ad Revenue Analytics",
        link: "ad-revenue",
        component: <AdRevenue />,
      },
      {
        name: "Ad Performance Analytics",
        link: "ad-performance",
        component: <AdPerformance />,
      },
    ],
  },
  {
    name: "Ad Management",
    link: "ad-management",
    component: <AdManagement />,
    children: [
      {
        //remove name for dynamic routes
        link: "ad-management/:id",
        component: <SingleAd />,
      },
    ],
  },
  {
    name: "Watchers Management",
    link: "watchers",
    component: <WatchersManagement />,
    children: [
      {
        name: "Watcher's Analytics",
        link: "watchers-analytics",
        component: <UserAnalytics />,
      },
    ],
  },
  {
    name: "Advertisers Management",
    link: "advertisers",
    component: <AdvertisersManagement />,
    children: [
      {
        name: "Shekels Analytics",
        link: "shekel",
        component: <ShekelsAnalytics />,
      },
      {
        name: "Manage Conversion Rate",
        link: "manage",
        component: <ManageConversionRate />,
      },
      {
        name: "Revenue Tracking",
        link: "revenue",
        component: <RevenueTracking />,
      },
      {
        name: "Transaction History",
        link: "transaction-history",
        component: <TransactionHistory />,
      },
    ],
  },
  {
    name: "Admin Management",
    link: "admin",
    component: <AdminManagement />,
  },
  {
    name: "Tribe Management",
    link: "tribes",
    component: <TribeManagement />,
  },
];
