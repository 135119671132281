import { Dropdown } from "antd";
import styled from "styled-components";

export const StyledDropdown = styled(Dropdown)``;

export const StyledDropdownMenu = styled.div`
  /* padding: 16px 10px; */
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.color.black50}`};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow.shadowXl};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  /* gap: 10px; */
  min-width: 96px;
  overflow: hidden;
`;

export const StyledDropdownMenuItem = styled.div`
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.gray100};
  }
  & > * {
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    padding: 10px 16px;
    text-align: left;
  }
`;
