import { Lock, Mail } from "@/assets/vectors";
import { StyledButton } from "@/components/Auth/Button/Button.style";
import { StyledForm } from "@/components/Auth/Form/Form.styles";
import FormHeader from "@/components/Auth/FormHeader/FormHeader";
import { StyledInput } from "@/components/Auth/Input/Input.style";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import AuthLayout from "@/layouts/AuthLayout/AuthLayout";
import { Link } from "react-router-dom";
import { SigninFieldType } from "./Signin.types";
import { Form, message } from "antd";
import { required } from "@/utils/validation";
import { FC } from "react";
import { login } from "@/network/auth";
import { useMutation } from "@tanstack/react-query";
import { REFRESH_TOKEN_NAME, TOKEN_NAME } from "@/constants/api";

const SignIn: FC = () => {
  const { mutate, data, isPending } = useMutation({
    mutationFn: login,
  });

  if (data) {
    if ("statusCode" in data) {
      message.error(data.message);
    } else {
      message.success(data.message);
      // sessionStorage.setItem(TOKEN_NAME, data.data.tokens.accessToken);
      // sessionStorage.setItem(REFRESH_TOKEN_NAME, data.data.tokens.refreshToken);
      // window.location.replace("/");
    }
  }

  const onFinish = async (values: SigninFieldType) => {
    console.log("Success:", values);
    mutate({
      ...values,
      redirectUrl: window.location.origin,
    });
  };

  return (
    <AuthLayout>
      <StyledForm layout="vertical" name="signin" onFinish={onFinish}>
        <StyledFlex vertical align="flex-start" gap={32} $alignSelf="stretch">
          <FormHeader
            heading="Sign In"
            subHeading="Welcome Back! Fill in the details below"
          />
          <StyledFlex gap={20} vertical align="stretch" $alignSelf="stretch">
            <Form.Item<SigninFieldType>
              name="email"
              rules={[
                required("Please input your Email!"),
                {
                  type: "email",
                  message: "Please input a valid Email",
                },
              ]}
            >
              <StyledInput type="email" prefix={<Mail />} placeholder="Email" />
            </Form.Item>
            <Form.Item<SigninFieldType>
              name="password"
              rules={[required("Please input your Password")]}
            >
              <StyledInput
                prefix={<Lock />}
                placeholder="Password"
                type="password"
              />
            </Form.Item>
            <Link to="/forgot-password">
              <StyledTypography
                $textAlign="right"
                $variantColor="primary"
                $variantSize="md"
              >
                Forgot Password?
              </StyledTypography>
            </Link>
          </StyledFlex>
        </StyledFlex>
        <StyledButton
          disabled={isPending}
          loading={isPending}
          type="primary"
          htmlType="submit"
        >
          Continue
        </StyledButton>
      </StyledForm>
    </AuthLayout>
  );
};

export default SignIn;
