import { ConfigProvider, Form, TabsProps } from "antd";
import {
  AccountCardWrapper,
  SettingsAccountLayout,
  SettingsLayout,
  ShekelConversions,
  StyledModalButtonContainer,
  StyledSuccessContainer,
  StyledTableImg,
  ValueInNaira,
} from "./Settings.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { settingsAntdTheme } from "./Settings.theme";
import { StyledTabs } from "@/components/Dashboard/Tabs/Tabs.styles";
import { StyledCard } from "@/components/Dashboard/Card/Card.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";
import { Plus, Success } from "@/assets/vectors";
import Table from "@/components/Dashboard/Table/Table";
import { StyledPill } from "@/components/Dashboard/Pill/Pill.styles";
import type { ColumnsType } from "antd/es/table";
import AvartarImg from "@/assets/images/avartar.png";
import Modal from "@/components/Dashboard/Modal/Modal";
import { useState } from "react";
import { AddShekelsType, SetThresholdType } from "./Settings.types";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { required } from "@/utils/validation";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";

interface DataType {
  key: string;
  name: string;
  type: string;
  image: string;
  role: string;
  date: string;
  amount: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Admin",
    key: "name",
    render: (_, { name, role }) => (
      <StyledFlex gap={12} align="center">
        <StyledTableImg src={AvartarImg} alt="" />
        <StyledTypography $variantColor="gray90" $variantSize="sm">
          {name} | {role}
        </StyledTypography>
      </StyledFlex>
    ),
    width: "56%",
  },
  {
    title: "Transaction Type",
    key: "type",
    render: (_, { type }) => (
      <StyledPill $bgColor="black50" $color="gray700">
        Top-Up
      </StyledPill>
    ),
    width: "16%",
  },
  {
    title: "Amount",
    key: "amount",
    render: () => (
      <div>
        <StyledTypography $variantColor="gray90" $variantSize="sm">
          22 Shekels
        </StyledTypography>
        <StyledTypography $variantColor="gray500" $variantSize="xs">
          ₦ 4,400 in Naira
        </StyledTypography>
      </div>
    ),
    width: "13%",
  },
  {
    title: "Transaction Date ",
    key: "date",
    render: (_, { date }) => (
      <StyledTypography $variantColor="black100" $variantSize="sm">
        Nov 24, 2023
      </StyledTypography>
    ),
    width: "15%",
  },
];

const Settings = () => {
  const onChange = (key: string) => {
    console.log(key);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<
    "set-threshold" | "add-shekels" | ""
  >("");
  const [thresholdStatus, setThresholdStatus] = useState<
    "form" | "confirm" | "success"
  >("form");

  const [setThresholdForm] = Form.useForm<SetThresholdType>();

  const [addShekelsForm] = Form.useForm<AddShekelsType>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const onThresholdFinish = (values: SetThresholdType) => {
    console.log("Success:", values);
    setThresholdStatus("confirm");
  };

  const onShekelsFinish = (values: AddShekelsType) => {
    console.log("Success:", values);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Profile",
      children: <></>,
    },
    {
      key: "2",
      label: "Account Balance Threshold Setup",
      children: (
        <SettingsAccountLayout gap={24} $alignSelf="stretch" vertical>
          <AccountCardWrapper>
            <StyledCard>
              <StyledFlex
                gap={20}
                $alignSelf="stretch"
                align="stretch"
                vertical
              >
                <StyledFlex
                  gap={8}
                  $alignSelf="stretch"
                  align="center"
                  vertical
                >
                  <StyledTypography
                    $variantColor="black80"
                    $variantSize="md"
                    $fontWeight={500}
                  >
                    Current Balance
                  </StyledTypography>
                  <StyledFlex
                    gap={4}
                    align="center"
                    justify="center"
                    $alignSelf="stretch"
                  >
                    <StyledTypography
                      $variantColor="black100"
                      $variantSize="3xl"
                      $fontWeight={500}
                    >
                      81,910.00
                    </StyledTypography>
                    <StyledTypography
                      $variantColor="black80"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Shekels
                    </StyledTypography>
                  </StyledFlex>
                  <ValueInNaira>
                    <StyledTypography
                      $variantColor="black90"
                      $variantSize="xs"
                      $fontWeight={500}
                    >
                      (Value in Naira: ₦16,382,000)
                    </StyledTypography>
                  </ValueInNaira>
                  <ShekelConversions>
                    <StyledTypography
                      $variantColor="white"
                      $variantSize="md"
                      $fontWeight={500}
                    >
                      1 shekel = ₦200
                    </StyledTypography>
                  </ShekelConversions>
                </StyledFlex>

                <StyledButton
                  onClick={() => {
                    setModalType("add-shekels");
                    showModal();
                  }}
                  type="primary"
                >
                  <Plus />
                  <span>Add Shekel</span>
                </StyledButton>
                <StyledDivider />
                <button
                  onClick={() => {
                    setModalType("set-threshold");
                    showModal();
                  }}
                >
                  <StyledTypography
                    $variantColor="primary"
                    $variantSize="sm"
                    $fontWeight={500}
                  >
                    Set Threshold
                  </StyledTypography>
                </button>
              </StyledFlex>
            </StyledCard>
          </AccountCardWrapper>
          <div>
            <StyledTypography
              $mb={"16px"}
              $variantColor="gray90"
              $variantSize="lg"
            >
              Transaction data
            </StyledTypography>
            <Table
              columns={columns}
              pageSize={10}
              isPaginated
              dataSource={[
                {
                  key: "0",
                  name: "Nicholas James",
                  image: "string",
                  type: "string",
                  role: "string",
                  date: "string",
                  amount: 0,
                },
                {
                  key: "1",
                  name: "Adesina Ojo",
                  image: "string",
                  type: "string",
                  role: "string",
                  date: "string",
                  amount: 0,
                },
                {
                  key: "2",
                  name: "Adesina Ojo",
                  image: "string",
                  type: "string",
                  role: "string",
                  date: "string",
                  amount: 0,
                },
                {
                  key: "3",
                  name: "Adesina Ojo",
                  image: "string",
                  type: "string",
                  role: "string",
                  date: "string",
                  amount: 0,
                },
              ]}
            />
          </div>
        </SettingsAccountLayout>
      ),
    },
  ];

  let setThresholdModalContent: JSX.Element = <></>;
  let setThresholdModalFooter: JSX.Element = <></>;
  let setThresholdHeading: string = "";

  if (thresholdStatus === "form") {
    setThresholdModalContent = (
      <StyledForm
        form={setThresholdForm}
        layout="vertical"
        name="set-threshold-form"
        onFinish={onThresholdFinish}
      >
        <StyledFlex gap={8} vertical $alignSelf="stretch">
          <StyledFlex gap={8} justify="space-between">
            <StyledTypography
              $variantColor="black100"
              $variantSize="sm"
              $fontWeight={500}
            >
              Minimum balance
            </StyledTypography>
            <ValueInNaira>
              <StyledTypography
                $variantColor="black90"
                $variantSize="xs"
                $fontWeight={500}
              >
                (Value in Naira: ₦0.00)
              </StyledTypography>
            </ValueInNaira>
          </StyledFlex>
          <StyledForm.Item<SetThresholdType>
            name="minAmount"
            rules={[
              required("Please input an amount!"),
              // {
              //   type: "number",
              //   message: "Please input a number",
              // },
            ]}
            // label="Email Address"
          >
            <StyledInput
              type="text"
              placeholder="Enter a minimum amount"
              suffix={
                <StyledFlex gap={8} align="center">
                  <StyledTypography $variantColor="black60" $variantSize="xl">
                    |
                  </StyledTypography>
                  <StyledTypography $variantColor="black100" $variantSize="md">
                    Shekels
                  </StyledTypography>
                </StyledFlex>
              }
            />
          </StyledForm.Item>
        </StyledFlex>
      </StyledForm>
    );
    setThresholdModalFooter = (
      <>
        <StyledButton onClick={hideModal} type="default">
          Cancel
        </StyledButton>
        <StyledButton
          form="set-threshold-form"
          key="submit"
          htmlType="submit"
          type="primary"
        >
          Continue
        </StyledButton>
      </>
    );
    setThresholdHeading = "Set a threshold";
  } else if (thresholdStatus === "confirm") {
    setThresholdModalContent = (
      <StyledTypography
        $variantColor="black90"
        $variantSize="md"
        $fontWeight={400}
      >
        You are about to make{" "}
        <StyledTypography
          $variantColor="black90"
          $variantSize="md"
          $fontWeight={500}
        >
          50 shekels (Value in Naira: ₦1,000.00)
        </StyledTypography>{" "}
        to your lohli account
      </StyledTypography>
    );
    setThresholdModalFooter = (
      <>
        <StyledButton onClick={hideModal} type="default">
          Cancel
        </StyledButton>
        <StyledButton
          onClick={() => {
            setThresholdStatus("success");
          }}
          type="primary"
        >
          Yes, Proceed
        </StyledButton>
      </>
    );
    setThresholdHeading = "Are you sure you want to proceed?";
  } else if (thresholdStatus === "success") {
    setThresholdModalContent = (
      <StyledFlex gap={0} vertical $alignSelf="stretch" align="center">
        <Success />
        <StyledSuccessContainer vertical align="center">
          <StyledTypography
            $variantColor="black100"
            $variantSize="xl"
            $fontWeight={500}
            $mb={"16px"}
          >
            Success!
          </StyledTypography>
          <StyledTypography
            $variantColor="black90"
            $variantSize="sm"
            $fontWeight={400}
            $textAlign="center"
          >
            Your minimum balance of 50 shekels{" "}
            <StyledTypography
              $variantColor="black100"
              $variantSize="sm"
              $fontWeight={500}
            >
              (₦1000.00)
            </StyledTypography>{" "}
            has been set
          </StyledTypography>
        </StyledSuccessContainer>
      </StyledFlex>
    );

    setThresholdModalFooter = (
      <>
        <StyledButton
          onClick={() => {
            hideModal();
            setThresholdStatus("form");
            setModalType("");
          }}
          type="primary"
        >
          OK!
        </StyledButton>
      </>
    );

    setThresholdHeading = "";
  }

  const addShekelsModalContent = (
    <StyledForm
      form={addShekelsForm}
      layout="vertical"
      name="add-shekels-form"
      onFinish={onShekelsFinish}
    >
      <StyledFlex gap={4} vertical $alignSelf="stretch">
        <StyledForm.Item<AddShekelsType> name="amount" rules={[]}>
          <StyledInput
            type="text"
            placeholder="Enter Amount"
            prefix={
              <StyledTypography $variantColor="black100" $variantSize="md">
                ₦
              </StyledTypography>
            }
          />
        </StyledForm.Item>
        <StyledTypography
          $variantColor="black100"
          $variantSize="xs"
          $fontWeight={400}
          $textAlign="center"
        >
          1 shekel = ₦200
        </StyledTypography>
      </StyledFlex>
    </StyledForm>
  );

  const addShekelsModalFooter = (
    <>
      <StyledButton onClick={hideModal} type="default">
        Cancel
      </StyledButton>
      <StyledButton
        form="add-shekels-form"
        key="submit"
        htmlType="submit"
        type="primary"
      >
        Continue
      </StyledButton>
    </>
  );

  return (
    <ConfigProvider theme={settingsAntdTheme}>
      {/* modal */}
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        heading={
          modalType === "set-threshold"
            ? setThresholdHeading
            : modalType === "add-shekels"
            ? "Add Shekels"
            : ""
        }
        footer={
          <StyledModalButtonContainer flex={"1 1 0"} justify="stretch">
            {modalType === "set-threshold"
              ? setThresholdModalFooter
              : modalType === "add-shekels"
              ? addShekelsModalFooter
              : ""}
          </StyledModalButtonContainer>
        }
      >
        {modalType === "set-threshold"
          ? setThresholdModalContent
          : modalType === "add-shekels"
          ? addShekelsModalContent
          : ""}
      </Modal>
      <SettingsLayout gap={32} vertical $alignSelf="stretch">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Settings
        </StyledTypography>
        <StyledDivider />
        <StyledTabs defaultActiveKey="2" items={items} onChange={onChange} />
      </SettingsLayout>
    </ConfigProvider>
  );
};

export default Settings;
