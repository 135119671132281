import {
  IPaginationMeta,
  IPaginationParams,
  ISODateString,
} from "@/types/global";
import API, { BaseResponse } from "./API";
import Endpoints from "./endpoints";
import { IAdvert } from "@/types/advert";

type Advert = IAdvert;

interface IAdComment {
  id: number;
  comment: string;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  metadata: {
    id: number;
    totalLikes: number;
    totalReplies: number;
    commentId: number;
  };
  user: {
    id: number;
    profile: {
      avatar: {
        publicURL: string;
      };
      firstName: string;
      lastName: string;
      gender: string;
      username: string;
    };
  };
  engagement: null;
}

export interface InfinitePage {
  data: IAdComment[];
  previousCursor?: number;
  nextCursor?: number;
}

export const getAdverts = async (
  params: IPaginationParams & { search?: string }
): Promise<BaseResponse<Advert[], IPaginationMeta>> => {
  return API.get<Advert[], IPaginationMeta>(Endpoints.ADVERTISEMENTS, {
    params: { ...params },
  });
};

export const getAdvert = async (id: string): Promise<BaseResponse<Advert>> => {
  return API.get<Advert>(`${Endpoints.ADVERTISEMENTS}/${id}`);
};

export const getAdvertCommentReplies = async (
  { adId, commentId }: { adId: string; commentId: string },
  params: IPaginationParams & { userId?: string }
): Promise<BaseResponse<IAdComment[], IPaginationMeta>> => {
  const res = await API.get<IAdComment[], IPaginationMeta>(
    Endpoints.getAdCommentReplies(adId, commentId),
    {
      params: { ...params },
    }
  );
  return res;
};

export const deleteCommentReply = async ({
  adId,
  commentId,
  replyId,
}: {
  adId: string;
  replyId: string;
  commentId: string;
}): Promise<BaseResponse<IAdComment>> => {
  const res = await API.delete<IAdComment>(
    Endpoints.deleteAdCommentReply(adId, commentId, replyId)
  );
  return res;
};

export const getAdvertComments = async (
  id: string,
  params: IPaginationParams & { userId?: string }
): Promise<BaseResponse<IAdComment[], IPaginationMeta>> => {
  const res = await API.get<IAdComment[], IPaginationMeta>(
    Endpoints.getAdComments(id),
    {
      params: { ...params },
    }
  );
  return res;
};

export const deleteComment = async ({
  adId,
  commentId,
}: {
  adId: string;
  commentId: string;
}): Promise<BaseResponse<IAdComment>> => {
  const res = await API.delete<IAdComment>(
    Endpoints.deleteAdComment(adId, commentId)
  );
  return res;
};
