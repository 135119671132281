import { Form } from "antd";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
  align-self: stretch;
  & .ant-form-item {
    margin-bottom: 0;
  }
`;
