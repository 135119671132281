import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  FilterContainerOne,
  FilterContainerTwo,
} from "./AnalyticsFilter.styles";
import { StyledRangePicker } from "@/components/Dashboard/RangePicker/RangePicker.styles";
import Select from "@/components/Dashboard/Select/Select";
import { StyledExportButton } from "@/components/Dashboard/Button/Button.styles";
import { FC, useCallback, useState } from "react";
import { IAnalyticsFilterProps } from "./AnalyticsFilter.types";
import { Download } from "@/assets/vectors";
import { DateTime } from "luxon";
import { QUERY_KEY_ADVERTISERS, QUERY_KEY_TRIBES } from "@/constants/api";
import { useQuery } from "@tanstack/react-query";
import { getAdvertisers } from "@/network/user";
import { debounce } from "@/utils/helper-functions";
import { Spin } from "antd";
import { getTribes } from "@/network/tribe";

const AnalyticsFilter: FC<IAnalyticsFilterProps> = ({
  advertiserId,
  dateRange,
  setAdvertiserId,
  setDateRange,
  setTribeId,
  tribeId,
}) => {
  const [advertiserSearch, setAdvertiserSearch] = useState("");

  const { data: advertisersData, isPending: isPendingAdvertisers } = useQuery({
    queryKey: [QUERY_KEY_ADVERTISERS, advertiserSearch],
    queryFn: async () =>
      getAdvertisers({
        limit: 100,
        page: 1,
        search: advertiserSearch,
      }),
  });

  const { data: tribesData, isPending: isPendingTribe } = useQuery({
    queryKey: [QUERY_KEY_TRIBES, advertiserSearch],
    queryFn: async () =>
      getTribes({
        limit: 100,
        page: 1,
      }),
  });

  const advertisers =
    advertisersData && "error" in advertisersData
      ? null
      : advertisersData?.data;

  const tribes = tribesData && "error" in tribesData ? null : tribesData?.data;

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        console.log(value);
        setAdvertiserSearch(value);
      },
      1000,
      false
    ),
    []
  );

  return (
    <StyledFlex gap={32} align="center" justify="space-between">
      <StyledFlex gap={32} flex="1" align="center">
        <FilterContainerOne>
          <StyledRangePicker
            value={[
              dateRange[0] ? DateTime.fromISO(dateRange[0]) : null,
              dateRange[1] ? DateTime.fromISO(dateRange[1]) : null,
            ]}
            ranges={{
              Today: [
                DateTime.now().startOf("day"),
                DateTime.now().endOf("day"),
              ],
              "This Week": [
                DateTime.now().startOf("week").startOf("day"),
                DateTime.now().endOf("week").endOf("day"),
              ],
              "This Month": [
                DateTime.now().startOf("month").startOf("day"),
                DateTime.now().endOf("month").endOf("day"),
              ],
              "This Year": [
                DateTime.now().startOf("year").startOf("day"),
                DateTime.now().endOf("year").endOf("day"),
              ],
            }}
            onChange={(dates) => {
              if (dates === null) {
                setDateRange(["", ""]);
              } else if (dates.length) {
                console.log([
                  dates[0]?.startOf("day").toISO() ?? "",
                  dates[1]?.endOf("day").toISO() ?? "",
                ]);
                setDateRange([
                  dates[0]?.startOf("day").toISO() ?? "",
                  dates[1]?.endOf("day").toISO() ?? "",
                ]);
              }
            }}
          />
        </FilterContainerOne>
        <FilterContainerOne>
          <Select
            onSelect={(value) => {
              setTribeId?.(`${value?.value}`);
            }}
            onSearch={() => console.log("Eee")}
            showSearch
            placeholder="Filter Tribe"
            filterOption={false}
            options={tribes?.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            loading={isPendingTribe}
            notFoundContent={isPendingTribe ? <Spin size="small" /> : null}
          />
        </FilterContainerOne>
        <FilterContainerTwo>
          <Select
            onSelect={(value) => {
              setAdvertiserId?.(`${value?.value}`);
            }}
            filterOption={false}
            labelInValue
            showSearch
            onSearch={handleSearch}
            placeholder="Filter Advertisers"
            options={advertisers?.map((item) => ({
              label: item?.profile?.username ?? "",
              value: item.id,
            }))}
            loading={isPendingAdvertisers}
            notFoundContent={
              isPendingAdvertisers ? <Spin size="small" /> : null
            }
          />
        </FilterContainerTwo>
      </StyledFlex>
      <StyledExportButton type="primary">
        <Download />
        <span>Export</span>
      </StyledExportButton>
    </StyledFlex>
  );
};

export default AnalyticsFilter;
