import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  TribeManagementLayout,
  SearchContainer,
  StyledActionBtn,
  StyledAddTribeActionButtonContainer,
} from "./TribeManagement.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledCard } from "@/components/Dashboard/Card/Card.styles";
import {
  StyledInput,
  StyledTextArea,
} from "@/components/Dashboard/Input/Input.styles";
import { Delete, Edit, Plus, Search } from "@/assets/vectors";
import {
  StyledButton,
  StyledDeleteButton,
} from "@/components/Dashboard/Button/Button.styles";
import type { ColumnsType } from "antd/es/table";
import Table from "@/components/Dashboard/Table/Table";
import { useState } from "react";
import Modal from "@/components/Dashboard/Modal/Modal";
import { AddTribeFieldType } from "./TribeManagement.types";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { required } from "@/utils/validation";
import { Form, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createTribe,
  deleteTribe,
  editTribe,
  getTribes,
} from "@/network/tribe";
import { QUERY_KEY_TRIBES } from "@/constants/api";

interface DataType {
  key: string;
  name: string;
  description: string;
}

const TRIBE_PAGINATION_LIMIT = 12;

const TribeManagement = () => {
  const [page, setPage] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalFormType, setModalFormType] = useState<"add" | "edit" | "nil">(
    "nil"
  );
  const [selectedTribe, setSelectedTribe] = useState<DataType | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  // Access the client
  const queryClient = useQueryClient();

  const { data: tribesData, isPending: isPendingTribes } = useQuery({
    queryKey: [QUERY_KEY_TRIBES, page],
    queryFn: async () =>
      getTribes({
        limit: TRIBE_PAGINATION_LIMIT,
        page: page,
      }),
  });

  const { mutateAsync: mutateCreateTribe, isPending: isPendingCreateTribe } =
    useMutation({
      mutationFn: createTribe,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_TRIBES],
        });
      },
    });

  const { mutateAsync: mutateEditTribe, isPending: isPendingEditTribe } =
    useMutation({
      mutationFn: editTribe,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_TRIBES],
        });
      },
    });

  const { mutateAsync: mutateDeleteTribe, isPending: isPendingDeleteTribe } =
    useMutation({
      mutationFn: deleteTribe,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_TRIBES],
        });
      },
    });

  const [form] = Form.useForm<AddTribeFieldType>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
    setModalFormType("nil");
  };

  const onFinish = async (values: AddTribeFieldType) => {
    console.log("Success:", values);
    if (modalFormType === "add") {
      const res = await mutateCreateTribe({
        name: values.name,
        description: values.description,
        uuid: null,
      });
      if ("error" in res) {
        message.error(res.error);
        return;
      } else {
        message.success("Tribe created successfully!");
      }
      hideModal();
    } else {
      const res = await mutateEditTribe({
        name: values.name,
        description: values.description,
        id: selectedTribe?.key ?? "",
      });
      if ("error" in res) {
        message.error(res.error);
        return;
      } else {
        message.success("Tribe updated successfully!");
      }
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      key: "name",
      render: (_, { name }) => (
        <StyledTypography $variantColor="gray90" $variantSize="sm">
          {name}
        </StyledTypography>
      ),
      width: "30%",
    },
    {
      title: "Description",
      key: "description",
      render: (_, { description }) => (
        <StyledTypography $variantColor="gray500" $variantSize="sm">
          {description}
        </StyledTypography>
      ),
      width: "50%",
    },
    {
      title: "",
      key: "key",
      render: (_, { description, name, key }) => (
        <StyledFlex gap={4} align="center">
          <StyledActionBtn
            onClick={() => {
              setShowDelete(true);
              setSelectedTribe({ name, description, key });
            }}
          >
            <Delete />
          </StyledActionBtn>
          <StyledActionBtn
            onClick={() => {
              form.setFieldValue("name", name);
              form.setFieldValue("description", description);
              setModalFormType("edit");
              setSelectedTribe({ name, description, key });
              showModal();
            }}
          >
            <Edit />
          </StyledActionBtn>
        </StyledFlex>
      ),
      width: "8%",
    },
  ];

  if (tribesData && "error" in tribesData) {
    return null;
  }

  return (
    <TribeManagementLayout gap={32} vertical $alignSelf="stretch">
      {/* Create and Edit tribe */}
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        heading="Add a Tribe"
        footer={
          <StyledAddTribeActionButtonContainer justify="flex-end">
            <StyledButton
              form="add-tribe"
              key="submit"
              htmlType="submit"
              type="primary"
              loading={isPendingCreateTribe || isPendingEditTribe}
            >
              {modalFormType === "add" ? "Create" : "Update"}
            </StyledButton>
            <StyledButton onClick={hideModal} type="default">
              Cancel
            </StyledButton>
          </StyledAddTribeActionButtonContainer>
        }
      >
        <StyledForm
          form={form}
          layout="vertical"
          name="add-tribe"
          onFinish={onFinish}
        >
          <StyledFlex gap={16} vertical $alignSelf="stretch">
            <StyledForm.Item<AddTribeFieldType>
              name="name"
              rules={[required("Please input the name!")]}
              label="Name"
            >
              <StyledInput type="text" placeholder="Enter Name" />
            </StyledForm.Item>
            <StyledForm.Item<AddTribeFieldType>
              name="description"
              rules={[required("Please input the description!")]}
              label="Description"
            >
              <StyledTextArea placeholder="Enter Description" />
            </StyledForm.Item>
          </StyledFlex>
        </StyledForm>
      </Modal>
      {/* delete modal  */}
      <Modal
        isModalOpen={showDelete}
        setIsModalOpen={setShowDelete}
        heading="Are you sure you want to delete this Tribe?"
        footer={
          <StyledFlex justify="flex-end">
            <StyledButton
              onClick={() => {
                setShowDelete(false);
              }}
              type="default"
            >
              Cancel
            </StyledButton>
            <StyledDeleteButton
              loading={isPendingDeleteTribe}
              onClick={async () => {
                const res = await mutateDeleteTribe({
                  id: selectedTribe?.key ?? "",
                });
                if ("error" in res) {
                  message.error(res.error);
                  return;
                } else {
                  message.success("Tribe deleted successfully!");
                  setShowDelete(false);
                }
              }}
              type="primary"
            >
              Yes, I want to delete this Tribe
            </StyledDeleteButton>
          </StyledFlex>
        }
      >
        <StyledFlex gap={38} vertical $alignSelf="stretch">
          <StyledTypography as="p" $variantColor="black100" $variantSize="md">
            You are about to delete{" "}
            <StyledTypography
              as="span"
              $variantColor="black100"
              $variantSize="md"
              $fontWeight={500}
            >
              “{selectedTribe?.name}”.
            </StyledTypography>
          </StyledTypography>
        </StyledFlex>
      </Modal>
      <StyledFlex vertical gap={4} align="flex-start">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Tribe Management
        </StyledTypography>
        <StyledTypography $variantColor="gray500" $variantSize="md">
          Add and manage other tribes.
        </StyledTypography>
      </StyledFlex>
      <StyledFlex gap={24} $alignSelf="stretch" align="center">
        {[
          {
            title: "Total Tribes",
            value: (tribesData && tribesData.totalDocuments) ?? 0,
          },
        ].map((item) => (
          <StyledFlex
            key={item.title}
            flex={"1 1 0"}
            $alignSelf="stretch"
            align="center"
          >
            <StyledCard>
              <StyledFlex gap={8} $alignSelf="stretch" vertical>
                <StyledTypography
                  $variantColor="black90"
                  $variantSize="sm"
                  $fontWeight={500}
                >
                  {item.title}
                </StyledTypography>
                <StyledTypography
                  $variantColor="black100"
                  $variantSize="3xl"
                  $fontWeight={500}
                >
                  {item.value}
                </StyledTypography>
              </StyledFlex>
            </StyledCard>
          </StyledFlex>
        ))}
      </StyledFlex>
      <StyledFlex gap={16} $alignSelf="stretch" justify="space-between">
        {/* <SearchContainer>
          <StyledInput prefix={<Search />} placeholder="Search" />
        </SearchContainer> */}
        <StyledButton
          onClick={() => {
            setModalFormType("add");
            form.setFieldValue("name", "");
            form.setFieldValue("description", "");
            showModal();
          }}
          type="primary"
        >
          <Plus />
          <span>Add Tribe</span>
        </StyledButton>
      </StyledFlex>
      <Table
        columns={columns}
        pageSize={TRIBE_PAGINATION_LIMIT}
        onPaginationChange={(page) => {
          setPage(page);
          console.log(page);
        }}
        isPaginated
        dataSource={
          tribesData?.data?.map((item) => ({
            key: item.id.toString(),
            name: item.name,
            description: item.description ?? "",
          })) ?? []
        }
        total={(tribesData && tribesData.totalDocuments) ?? 0}
        loading={isPendingTribes}
      />
    </TribeManagementLayout>
  );
};

export default TribeManagement;
