import styled from "styled-components";
import { IDividerProps } from "./Divider.types";

export const StyledDivider = styled.div<IDividerProps>`
  width: ${({ $width }) => $width ?? "100%"};
  height: ${({ $height }) => $height ?? "1px"};
  margin-bottom: ${({ $mb }) => $mb};
  background-color: ${({ $bg, theme }) =>
    `${$bg ? theme.color[$bg] : theme.color.black60}`};
`;
