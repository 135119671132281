import { FC } from "react";
import { StyledRangePicker } from "./RangePicker.styles";
import { RangePickerProps } from "./RangePicker.types";
import { Calendar } from "@/assets/vectors";

const DatePicker: FC<RangePickerProps> = (props) => {
  return <StyledRangePicker {...props} suffixIcon={<Calendar />} />;
};

export default DatePicker;
