import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const AdsManagementTableContainer = styled(StyledFlex)`
  width: 100%;
`;

export const SearchContainer = styled.div`
  width: 328px;
`;

export const VideoContentData = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
`;

export const VideoContentDataText = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: start;
`;

export const VideoContentImg = styled.img`
  object-fit: cover;
  width: 88px;
  height: 73px;
`;

export const BanAdsActionButtonContainer = styled(StyledFlex)`
  & > *:first-child {
    width: 155px;
  }
  & > *:last-child {
    flex-grow: 1;
  }
`;
