import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { FC } from "react";
import { IFormHeaderProps } from "./FormHeader.types";

const FormHeader: FC<IFormHeaderProps> = ({ heading, subHeading }) => {
  return (
    <StyledFlex gap={12} vertical align="center" $alignSelf="stretch">
      <StyledTypography
        $textAlign="center"
        $variantColor="gray90"
        $variantSize="3xl"
        $fontWeight={600}
        as="h1"
      >
        {heading}
      </StyledTypography>
      <StyledTypography
        $textAlign="center"
        as="p"
        $variantColor="black90"
        $variantSize="md"
      >
        {subHeading}
      </StyledTypography>
    </StyledFlex>
  );
};

export default FormHeader;
