import { Input } from "antd";
import styled from "styled-components";

export const StyledInput = styled(Input)`
  height: 56px;
  width: 100%;
  padding: 2px 16px;
  & .ant-input-prefix {
    margin-inline-end: 4px;
  }
`;
