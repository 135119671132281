import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  display: flex;
  height: 48px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StyledExportButton = styled(StyledButton)`
  border-color: ${({ theme }) => `${theme.color.primary}`};
  width: fit-content;
  padding: 10px 34px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  &:hover {
    path {
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    path {
      stroke: ${({ theme }) => `${theme.color.white}`};
    }
  }
`;

export const StyledDeleteButton = styled(StyledButton)`
  border-color: ${({ theme }) => `${theme.color.red}`};
  background-color: ${({ theme }) => `${theme.color.red}`};
  color: ${({ theme }) => `${theme.color.white}`};
  width: fit-content;
  padding: 10px 34px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: ${({ theme }) => `${theme.color.red}`};
    background-color: ${({ theme }) => `${theme.color.red}`};
    color: ${({ theme }) => `${theme.color.white}`};
    path {
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    path {
      stroke: ${({ theme }) => `${theme.color.white}`};
    }
  }
`;
