import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  SearchContainer,
  ShekelsAnalyticsFilterContainerOne,
  ShekelsAnalyticsFilterContainerTwo,
  ShekelsAnalyticsLayout,
  VideoContentData,
  VideoContentDataText,
  VideoContentImg,
  WatchersImg,
} from "./ShekelsAnalytics.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import Select from "@/components/Dashboard/Select/Select";
import { Download, Search } from "@/assets/vectors";
import { StyledCard } from "@/components/Dashboard/Card/Card.styles";
import { ConfigProvider } from "antd";
import { shekelAnalyticsAntdTheme } from "./ShekelsAnalytics.theme";
import RangePicker from "@/components/Dashboard/RangePicker/RangePicker";
import { DateTime } from "luxon";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { StyledExportButton } from "@/components/Dashboard/Button/Button.styles";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import type { ColumnsType } from "antd/es/table";
import { applyEllipsis, debounce } from "@/utils/helper-functions";
import Table from "@/components/Dashboard/Table/Table";
import { shekelsData } from "@/utils/mock-data";
import { useQuery } from "@tanstack/react-query";
import {
  PAGINATION_LIMIT,
  QUERY_KEY_ADVERTISERS,
  QUERY_KEY_SHEKEL_METRICS,
  QUERY_KEY_TRIBES,
} from "@/constants/api";
import { ISODateString } from "@/types/global";
import { getShekelsMetrics } from "@/network/analytics";
import { useCallback, useState } from "react";
import Avatar from "@/assets/images/avatar.svg";
import AdsGif from "@/assets/images/ads-gif.gif";
import { getTribes } from "@/network/tribe";
import { getAdvertisers } from "@/network/user";
import AnalyticsFilter from "@/components/Dashboard/Analytics/AnalyticsFilter/AnalyticsFilter";

interface DataType {
  key: string;
  watchersImg: string;
  watchersName: string;
  advertisersName: string;
  tribe: string;
  shekelsEarned: string;
  date: string;
  videoImage: string;
  videoTitle: string;
  videoContent: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Watchers name",
    key: "watchersName",
    render: (_, { watchersImg, watchersName }) => (
      <StyledFlex gap="12px" align="center">
        <WatchersImg src={watchersImg} alt="" />
        <StyledTypography
          as="p"
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {watchersName}
        </StyledTypography>
      </StyledFlex>
    ),
    width: "21%",
  },
  {
    title: "Video Content",
    key: "videoTitle",
    render: (_, { videoContent, videoImage, videoTitle }) => (
      <VideoContentData>
        <VideoContentImg src={videoImage} alt="" />
        <VideoContentDataText>
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {videoTitle}
          </StyledTypography>
          <StyledTypography
            as="p"
            $variantColor="black80"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoContent, 54)}
          </StyledTypography>
        </VideoContentDataText>
      </VideoContentData>
    ),
    width: "32%",
  },
  {
    title: "Advertisers",
    key: "advertisersName",
    render: (_, { advertisersName }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {advertisersName}
      </StyledTypography>
    ),
    width: "12%",
  },
  {
    title: "Tribe",
    key: "tribe",
    render: (_, { tribe }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {tribe}
      </StyledTypography>
    ),
    width: "9%",
  },
  {
    title: "Shekels Earned",
    key: "shekelsEarned",
    render: (_, { shekelsEarned }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {shekelsEarned} Shekels
      </StyledTypography>
    ),
    width: "14%",
  },
  {
    title: " Date Earned",
    key: "date",
    render: (_, { date }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {date}
      </StyledTypography>
    ),
    width: "12%",
  },
];

const ShekelsAnalytics = () => {
  const [page, setPage] = useState<number>(1);
  const [dateRange, setDateRange] = useState<[string, string]>([
    DateTime.now().minus({ month: 1 }).startOf("day").toISO(),
    DateTime.now().endOf("day").toISO(),
  ]);
  const [advertiserSearch, setAdvertiserSearch] = useState("");
  const [tribeId, setTribeId] = useState<string>("");
  const [advertiserId, setAdvertiserId] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const { data: shekelsMetricsData, isLoading: isLoadingShekelsMetricData } =
    useQuery({
      queryKey: [
        QUERY_KEY_SHEKEL_METRICS,
        dateRange,
        page,
        advertiserId,
        tribeId,
        search,
      ],
      queryFn: async () =>
        getShekelsMetrics({
          limit: PAGINATION_LIMIT,
          startDate: dateRange[0] as ISODateString,
          endDate: dateRange[1] as ISODateString,
          page,
          advertiserId: advertiserId ?? "",
          tribeMemberId: tribeId ?? "",
          search,
        }),
    });

  const { data: tribesData } = useQuery({
    queryKey: [QUERY_KEY_TRIBES, page],
    queryFn: async () =>
      getTribes({
        limit: 100,
        page: 1,
      }),
  });

  const { data: advertisersData, isPending: isPendingAdvertisers } = useQuery({
    queryKey: [QUERY_KEY_ADVERTISERS, advertiserSearch],
    queryFn: async () =>
      getAdvertisers({
        limit: 100,
        page: 1,
        search: advertiserSearch,
      }),
  });

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearch(value);
        setPage(1);
      },
      1000,
      false
    ),
    []
  );

  const advertisers =
    advertisersData && "error" in advertisersData
      ? null
      : advertisersData?.data;

  const tribes = tribesData && "error" in tribesData ? null : tribesData?.data;

  const shekelMetrics =
    shekelsMetricsData && "error" in shekelsMetricsData
      ? null
      : shekelsMetricsData;

  console.log(shekelMetrics);

  return (
    <ConfigProvider theme={shekelAnalyticsAntdTheme}>
      <ShekelsAnalyticsLayout gap={32} vertical $alignSelf="stretch">
        <StyledFlex gap={4} vertical $alignSelf="stretch">
          <StyledTypography
            $variantColor="gray90"
            $variantSize="2xl"
            $fontWeight={500}
          >
            Shekels and Financial Analytics
          </StyledTypography>
          <StyledTypography $variantColor="black80" $variantSize="sm">
            View data on the total Shekels earned by users to understand the
            overall user engagement and rewards distribution.
          </StyledTypography>
        </StyledFlex>
        <StyledDivider />
        <AnalyticsFilter
          dateRange={dateRange}
          setDateRange={setDateRange}
          advertiserId={advertiserId}
          setAdvertiserId={setAdvertiserId}
          setTribeId={setTribeId}
          tribeId={tribeId}
        />
        \
        <StyledFlex align="stretch" gap={24} $alignSelf="stretch">
          {[
            {
              title: "Total Shekels Earned by All Watchers",
              value: `0`,
              subTitle: "(in Naira = ₦500,000)",
            },
            {
              title: "Shekel-to-Currency Conversion Rate",
              value: "1 shekel = ₦200",
              subTitle: "Updated January 2024",
            },
            {
              title: "Revenue from Shekel Conversions",
              value: `0`,
              subTitle: "(₦22,000,000 in Naira)",
            },
          ].map((item) => (
            <StyledFlex
              key={item.title}
              flex={"1 1 0"}
              $alignSelf="stretch"
              align="center"
            >
              <StyledCard>
                <StyledFlex gap={8} $alignSelf="stretch" vertical>
                  <StyledTypography
                    $variantColor="black90"
                    $variantSize="sm"
                    $fontWeight={400}
                  >
                    {item.title}
                  </StyledTypography>
                  <StyledTypography
                    $variantColor="black100"
                    $variantSize="2xl"
                    $fontWeight={500}
                  >
                    {item.value}
                  </StyledTypography>
                  <StyledTypography
                    $variantColor="black90"
                    $variantSize="xs"
                    $fontWeight={400}
                  >
                    {item.subTitle}
                  </StyledTypography>
                </StyledFlex>
              </StyledCard>
            </StyledFlex>
          ))}
        </StyledFlex>
        <SearchContainer>
          <StyledInput
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            prefix={<Search />}
            placeholder="Search"
          />
        </SearchContainer>
        <Table
          pageSize={PAGINATION_LIMIT}
          onPaginationChange={(page) => {
            setPage(page);
            console.log(page);
          }}
          columns={columns}
          dataSource={
            shekelMetrics?.data?.map((item) => ({
              key: item.id,
              watchersImg: Avatar,
              watchersName: `${item.user.profile.firstName} ${item.user.profile.lastName}`,
              advertisersName: item.advertisement.user.profile.username,
              tribe: "",
              shekelsEarned: `${item.amount}`,
              date: DateTime.fromISO(item.createdAt).toLocaleString(
                DateTime.DATETIME_MED
              ),
              videoImage: AdsGif,
              videoTitle: "",
              videoContent: "",
            })) ?? []
          }
          loading={isLoadingShekelsMetricData}
          isPaginated={true}
          total={(shekelMetrics && shekelMetrics.totalDocuments) ?? 0}
        />
      </ShekelsAnalyticsLayout>
    </ConfigProvider>
  );
};

export default ShekelsAnalytics;
