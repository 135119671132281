import styled, { css } from "styled-components";
import { ITypographyProps } from "./Typography.types";

export const StyledTypography = styled.span<ITypographyProps>`
  display: inline-block;
  font-style: normal;
  color: ${({ $variantColor, theme }) => theme.color[$variantColor]};
  text-align: ${({ $textAlign }) => $textAlign};
  font-weight: ${({ $fontWeight }) => $fontWeight ?? 400};
  margin-bottom: ${({ $mb }) => $mb};
  text-decoration-line: ${({ $underline }) => $underline};
  word-break: ${({ $wordBreak }) => $wordBreak ?? "break-all"};
  ${({ $variantSize, theme }) => css`
    font-size: ${theme.font[$variantSize].fontSize};
    line-height: ${theme.font[$variantSize].lineHeight};
    letter-spacing: ${theme.font[$variantSize].letterSpacing};
  `}
`;
