import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const StyledAuthLayoutContainer = styled.main`
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.color.primary};
`;

export const StyledFlexContainer = styled(StyledFlex)`
  width: 100%;
  min-height: 100vh;
`;

export const StyledAuthHeader = styled.header`
  padding: 48px 89px 0 89px;
`;

export const StyledAuthCard = styled(StyledFlex)`
  width: 90%;
  max-width: 485px;
  padding: 48px;
  //minimum auth card height - forgot password
  min-height: 288px;
  border-radius: 16px;
  border: ${(props) => `1px solid ${props.theme.color.black60}`};
  background-color: ${(props) => props.theme.color.white};
  box-shadow: ${(props) => props.theme.shadow.shadow3Xl};
`;

export const StyledAuthFooter = styled.footer`
  padding: 32px 112px;
`;
